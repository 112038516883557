<template>
    <main>
        <simple-header title="Detalles de la solicitud" :link="true" back="/admin"></simple-header>
        <section class="quote">
            <div class="container-fluid">
                <div class="w900">
                    <div>
                        <div class="box-left extended w-100">
                            <div class="btn btn-outline box-left spaced" @click="downloadQuotation">
                                <i class="fa fa-download"></i>
                                <span>DESCARGAR CSV</span>
                            </div>
                        </div>
                    </div>
                    <FormQuote :data="formData" :user="formUser" :readonly="true"></FormQuote>
                    <br>
                    <br>
                    <fieldset>
                        <legend>Canciones</legend>
                        <div class="pad20">
                            <QuoteSongs :records="songs" :readonly="true"></QuoteSongs>
                            <v-text-field color="blue" loading disabled v-if="loading"></v-text-field>
                        </div>
                    </fieldset>
                </div>
            </div>
            <loading-dialog :dialog="downloadDialog" text="Descargando"></loading-dialog>
        </section>
    </main>

</template>

<script>
    import FormQuote from "../../components/forms/FormQuote";
    import AdminHeader from "./partials/AdminHeader";
    import QuoteSongs from "../../components/tables/QuoteSongs";
    import LoadingDialog from "../../components/dialogs/LoadingDialog";
    import SimpleHeader from "../../components/SimpleHeader";

    const FileDownload = require('js-file-download');

    export default {
        name: "AdminSolicitud",
        components: {SimpleHeader, LoadingDialog, QuoteSongs, AdminHeader, FormQuote},
        data: () => ({
            songs: [],
            formData: {medios: {other_media: ''}, territories: {other_territories: ''}},
            formUser: {},
            downloadDialog: false,
            loading: false
        }),
        mounted() {
            this.loading = true;
            var self = this;
            this.$axios.get(`/admin-app/requests/${this.$route.params.id}`)
                .then(function (response) {
                    self.formData = response.data.formData;
                    self.formUser = response.data.formUser;
                    self.songs = response.data.songs;
                    self.loading = false;
                })
                .catch(function (error) {
                    self.$utils.validate(error.response, self);
                    self.loading = false;
                })
        },
        methods: {
            downloadQuotation: function () {
                this.downloadDialog = true;
                var self = this;
                this.$axios.get(`/admin-app/requests/${this.$route.params.id}/descargar`)
                    .then(function (response) {
                        FileDownload(response.data, 'Cotización.csv');
                        self.downloadDialog = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                        self.downloadDialog = false;
                    })
            }
        }
    }
</script>

<style scoped>
    h2 {
        margin: 0;
        color: #2B4959;
    }

    .quote{
        padding-top:1rem;
    }

</style>
