<script>
    import {Line, mixins} from "vue-chartjs";

    export default {
        name: 'line-chart',
        extends: Line,
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        }),
        methods: {
            render: function (chartData) {
                this.renderChart(chartData, this.options)
            }
        }
    };
</script>
