<template>
    <section class="cover-section" :class="{scrolled: classScroll}">
        <div class="naui-overlay-container">
            <video :poster="video" class="w-100" autoplay="true" loop="true" :src="video !== '' ? video : '@/assets/video/video-home.mp4'">
            </video>
            <div class="naui-overlay box-center">
                <div class="h-100 w600 vertical-flex">
                    <div>
                        <h1 class="text-center">{{ title }}</h1>
                    </div>
                    <div class="box-center double-spaced buttons">
                        <div @click="$emit('contact')" class="btn btn-rojo">CONTÁCTENOS</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="franja back-red text-center" ref="cover" id="franja-roja">
            <div class="w800 m-auto">
                <p>{{ description }}</p>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: "SplashCover",
        props: {
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            background: {
                type: String,
                default: 'https://placehold.it/1200x400',
            },
            video: {
                type: String,
                default: ''
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handle);
        },
        data() {
            return {
                top: null
            }
        },
        methods: {
            handle: function () {
                const ref = document.getElementById('franja-roja');
                this.top = ref ? ref.getBoundingClientRect().top : null;
            }
        },
        computed: {
            classScroll: function(){
                return this.top < 80 && this.top !== null;
            }
        }
    }
</script>

<style scoped>
    .vertical-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-height: 300px;
    }

    .buttons .btn {
        width: 200px;
        text-align: center;
        border-radius: 3rem;
        font-weight: 600;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .9rem;
    }

    .buttons .btn-transparent {
        background-color: rgba(255, 255, 255, 0.4);
        color: #fff;
    }

    h1 {
        font-family: cronos-pro, Lato, sans-serif;
        margin: 0;
        font-weight: 600;
        color: #fff;
    }

    @media(min-width: 1100px){
        h1{
            font-size: 3rem;
        }
    }

    .franja {
        padding: 1.5rem 0;
    }

    .franja p{
        font-weight: bold;
    }

    .btn-rojo {
        background: linear-gradient(to right, #F11B46, #BD1231);
    }

    .btn-rojo:active {
        background: #BD1231;
    }

    .franja p {
        font-size: 1.2rem;
        color: #fff;
        margin-bottom: 0;
    }

    .play {
        cursor: pointer;
        border-radius: 50%;
    }

    .play:active {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }

    video {
        display: block;
    }

    .naui-overlay {
        background-color: rgba(0, 0, 0, 0.4);
    }


    @media(max-width: 800px){
        .buttons{
            display: block;
            position: absolute;
            bottom:-136px;
        }

        .buttons>div:first-child{
            margin-bottom:1rem;
        }

        .cover-section{
            padding-bottom:160px;
            background-color: #f4f4f4;
        }

        .franja{
            position: relative;
            top:160px;
        }

        .buttons .btn-transparent{
            color:#333;
            border:1px solid #888;
            background-color: #fff;
        }

        .buttons .btn{
            margin-right: 0;
            font-size: .8rem;
        }

        .franja p{
            font-size: 1rem;
        }

        h1{
            font-size: 1.7rem;
            line-height: 1.4;
            padding:0 1rem;
        }

    }

</style>
