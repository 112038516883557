<template>
    <section>
        <v-container>
            <v-text-field
                    label="Titulo"
                    v-model="cancion.title"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Año de publicación"
                    v-model="cancion.year_publication"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Género"
                    v-model="cancion.genre"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Artista"
                    v-model="cancion.artist"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Título del album"
                    v-model="cancion.album"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="# Track"
                    v-model="cancion.tracknum"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Duración"
                    v-model="cancion.dur"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    v-if="readonly"
                    v-model="cancion.release_date"
                    label="Fecha de lanzamiento"
                    readonly
            ></v-text-field>
            <v-menu
                    v-if="!readonly"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="cancion.release_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                            v-model="cancion.release_date"
                            label="Fecha de lanzamiento"
                            readonly
                            v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="cancion.release_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(cancion.release_date)">OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
            <v-text-field
                    label="Autor (es)"
                    v-model="cancion.authors"
                    :readonly="readonly"
            ></v-text-field>
            <div class="form-group">
                <label>Tipo de derecho</label>
                <div class="box-left tipo-derecho double-spaced">
                    <div class="box-center record-right" v-if="readonly && cancion.fono">
                        <img height="35" src="@/assets/icons/autoral.svg">
                        <span>Autoral</span>
                    </div>
                    <div class="box-left record-rights record-right" v-if="!readonly && $route.path.startsWith('/admin/catalogo/')">
                        <input v-if="!readonly" type="checkbox" v-model="cancion.fono">
                        <img height="35" src="@/assets/icons/autoral.svg">
                        <span>Autoral</span>
                    </div>
                    <div class="box-center record-right">
                        <img height="35" src="@/assets/icons/fono.svg">
                        <span>Fono</span>
                    </div>
                </div>
            </div>
            <v-text-field
                    label="% Derecho autoral"
                    v-model="cancion.autoral"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Sello"
                    v-model="cancion.record_label"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Referencia"
                    v-model="cancion.reference"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="UFC"
                    v-model="cancion.UFC"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="ISRC"
                    v-model="cancion.ISRC"
                    :readonly="readonly"
            ></v-text-field>
            <v-text-field
                    label="Territorios"
                    v-model="cancion.territories"
                    :readonly="readonly">
            </v-text-field>
        </v-container>
    </section>

</template>

<script>
    export default {
        name: "FormSong",
        props: {
            cancion: {
                type: Object
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                menu: false,
            }
        }
    }
</script>

<style scoped>
    .tipo-derecho {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .record-right{
        width: max-content;
        min-width: 0;
        padding-right: 1.5rem;
    }

    .record-rights {
        flex-direction: unset;
    }

</style>
