<template>
    <main>
        <admin-search v-on:search="refresh" :search="filtros.querySearch" :actions="true"
                      :total="songCount"></admin-search>
        <section>
            <div class="container-fluid">
                <div class="box-left">
                    <filter-widget v-on:filterChange="refresh" admin="true" :filter-options="filtrosOpciones"
                                   :filter-values="filtros"></filter-widget>
                    <action-records v-if="selectedSongs.value.length > 0" :admin="true"
                                    v-on:addToList="addSongs"></action-records>
                </div>
            </div>
        </section>
        <admin-record-table v-on:addSong="addSingleSong" :checkboxes="true" :selected-songs="selectedSongs" v-on:change="refresh" :ini-records="catalog"
                            :pagination="pagination"></admin-record-table>
        <v-text-field color="#24404F" v-if="loading" loading disabled></v-text-field>
        <agregar-a-catalogo :active="addDialog" :records="sendRecords"></agregar-a-catalogo>
    </main>
</template>

<script>
    import AdminSearch from "./partials/AdminSearch";
    import AdminHeader from "./partials/AdminHeader";
    import FilterWidget from "../../components/widgets/FilterWidget";
    import AdminRecordTable from "../../components/tables/AdminRecordTable";
    import AgregarACatalogo from "../../components/AgregarACatalogo";
    import ActionRecords from "../../components/ActionRecords";

    export default {
        name: "AdminCatalog",
        components: {
            ActionRecords, AgregarACatalogo, AdminRecordTable, FilterWidget, AdminHeader, AdminSearch},
        data() {
            return {
                loading: false,
                catalog: [],
                songCount: 0,
                selectedSongs: {
                    value: []
                },
                sendRecords: [],
                filtros: {
                    querySearch: {value: ''},
                    catalogo: '',
                    autor: '',
                    genero: '',
                    derechos: ''
                },
                filtrosOpciones: {
                    catalogos: [],
                    autores: [],
                    generos: [],
                    derechos: []
                },
                pagination: {page: 1, pages: 1},
                addDialog: {value: false}
            }
        },
        mounted() {
            this.getHeader();
            this.refresh();
        },
        methods: {
            addSongs() {
                var selected = [];
                this.selectedSongs.value.forEach(function (item) {
                    selected.push(JSON.parse(item).id)
                });
                this.sendRecords = selected;
                this.addDialog.value = true
            },
            addSingleSong(song) {
                this.sendRecords = [song];
                this.addDialog.value = true
            },
            getHeader: function () {
                var self = this;
                this.$axios.get('admin-app/catalogo/header')
                    .then(function (response) {
                        self.filtrosOpciones = response.data.dropdowns;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    });
            },
            refresh: function (inputSearch) {
                this.loading = true;
                this.catalog = [];
                if (inputSearch !== undefined) {
                    this.filtros.querySearch.value = inputSearch
                }

                var self = this;
                this.$axios.get('admin-app/catalogo',
                    {
                        params: {
                            derechos: this.filtros.derechos,
                            q: this.filtros.querySearch.value,
                            p: this.pagination.page,
                            genero: this.filtros.genero,
                            categoria: this.filtros.catalogo,
                            autor: this.filtros.autor
                        }
                    })
                    .then(function (response) {
                        self.catalog = response.data.catalog;
                        self.songCount = response.data.count;
                        self.pagination = response.data.pagination;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self);
                        self.loading = false;
                    })
            },
        }
    }
</script>

<style scoped>

</style>
