<template>
    <main>
        <SimpleHeader :title="'Mi Perfil'" :link="false"></SimpleHeader>
        <!--<section>-->
            <!--<div class="container-fluid pad">-->
                <!--<div class="tabs box-left spaced">-->
                    <!--<div :class="{'active':active=='tab1'}" class="tab" @click="active='tab1'">-->
                        <!--DATOS PERSONALES-->
                    <!--</div>-->
                    <!--<div :class="{'active':active=='tab2'}" class="tab" @click="active='tab2'">-->
                        <!--MIS LISTAS-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</section>-->
        <section>
            <div class="container-fluid">
                <div v-if="active==='tab1'">
                    <datos-personales :perfil="perfil" :items="items" :editable="true"></datos-personales>
                </div>
                <!--<div v-if="active==='tab2'">-->
                    <!--<MisListas :listas-prop="playlists"></MisListas>-->
                <!--</div>-->
            </div>
        </section>
    </main>
</template>

<script>
    import SimpleHeader from "../../components/SimpleHeader";
    import MisListas from "./partials/MisListas";
    import DatosPersonales from "./partials/DatosPersonales";
    import HeaderClient from "../../components/HeaderClient";
    export default {
        name: "ClientProfile",
        components: {HeaderClient, DatosPersonales, MisListas, SimpleHeader},
        data() {
            return {
                perfil: {name: ''},
                items: [],
                active: 'tab1',
                playlists: []
            }
        },
        mounted() {
            var self = this;
            this.$axios.get('perfil')
                .then(function (response) {
                    self.perfil = response.data.user;
                    self.items = response.data.id_types;
                    self.playlists = response.data.playlists;
                })
                .catch(function (error) {
                    self.$utils.validate(error.response, self)
                })
        }
    }
</script>

<style scoped>


</style>
