<template>
    <main>
        <simple-header :title="header.name" :link="true" back="/inicio"></simple-header>
        <!--<img :src="header.img" :alt="header.name">-->
        <record-table v-on:addToList="addSingleSong" v-on:change="getSongs" :pagination="pagination"
                     :records="records"></record-table>
        <agregar-lista :active="actionOverlay" :records="sendSongs"></agregar-lista>
    </main>
</template>

<script>
    import SimpleHeader from "../../components/SimpleHeader";
    import RecordTable from "../../components/tables/RecordTable";
    import AgregarLista from "../../components/AgregarLista";

    export default {
        name: "ClientCategoryInterna",
        components: {AgregarLista, RecordTable, SimpleHeader},
        data: () => ({
            statusOptions: [
                {
                    text: 'Activo',
                    value: true
                },
                {
                    text: 'No activo',
                    value: false
                },
            ],
            header: {
                id: '',
                name: '',
                slug: '',
                img: []
            },
            records: [],
            pagination: {page: 1, pages: 1},
            actionOverlay: {value: false},
            sendSongs: {value: []},
            loading: false,
            snackbar: false,
        }),
        mounted() {
            var self = this;
            this.$axios.get(`subcategory/${this.$route.params.slug}/header`)
                .then(function (response) {
                    self.header = response.data.header;
                })
                .catch(function (error) {
                    self.$utils.validate(error.response, self)
                });
            this.getSongs();
        },
        methods: {
            getSongs: function () {
                var self = this;
                this.$axios.get(`subcategory/${this.$route.params.slug}/songs`,{
                    params: {
                        p: this.pagination.page
                    }
                })
                    .then(function (response) {
                        self.records = response.data.songs;
                        self.pagination = response.data.pagination;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            },
            addSingleSong: function (song) {
                this.sendSongs = {value: []};
                this.sendSongs.value.push(song);
                this.actionOverlay.value = true;
            },
        }
    }
</script>

<style scoped>
    .subInfo {
        display: grid;
        grid-template-columns: 400px 270px 1fr;
        align-items: center;
        grid-gap: 2rem;
    }

    .title {
        margin-bottom: .75rem;
    }

    .title img {
        margin-right:.75rem;
    }

    .subHeader {
        margin: 1rem;
    }
</style>
