<template>
    <div class="container-fluid">
        <div class="box-center data-analytics">
            <data-card :type="card.type" v-for="card in cards" :data="card.text" :value="datacards[card.value]"></data-card>
        </div>
    </div>
</template>

<script>
    import DataCard from "../../../../components/DataCard";

    export default {
        name: "AnalyticsData",
        components: {DataCard},
        props: ['datacards'],
        computed:{
            cards:function () {
                if(this.datacards !== undefined) {
                    return [{
                        text: 'Sesiones',
                        value: 'ga:sessions',
                        type: 'text'
                    }, {
                        text: 'Sesiones Nuevas',
                        value: 'ga:newUsers',
                        type: 'text'
                    }, {
                        text: 'Vistas de la página',
                        value: 'ga:pageviews',
                        type: 'text'
                    }, {
                        text: 'Duración promedio',
                        value: 'ga:avgSessionDuration',
                        type: 'time'
                    }, {
                        text: 'Tasa de rebote',
                        value: 'ga:bounceRate',
                        type: 'percentage'
                    }]
                } else {
                    return []
                }
            }
        }
    }
</script>

<style scoped>
@media(max-width: 1024px){
  .data-analytics{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:1rem;
  }
}

</style>
