<template>
    <v-dialog v-model="dialog.value" persistent max-width="400px">
        <v-card>
            <v-card-title>
                <span class="headline">¿Desea Borrar esta canción?</span>
            </v-card-title>
            <v-card-text>Confirme esta decisión ingresando la palabra 'borrar' abajo.</v-card-text>
            <v-card-text>
                <v-form ref="form">
                    <v-container>
                        <v-row>
                                <v-text-field v-model="text"
                                              required
                                              :rules="[rules.required]"
                                              :error-messages="errorMsg"
                                ></v-text-field>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="$emit('cancel')">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="confirm">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ConfirmDeleteDialog",
        props: ['dialog'],
        data: () => ({
            text: '',
            rules: {
                required: value => !!value || 'Requerido.',
            },
            errorMsg: []
        }),
        methods: {
            confirm: function () {
                if(this.$refs.form.validate) {
                    if(this.text.toLowerCase() === 'borrar') {
                        this.text = '';
                        this.errorMsg = [];
                        this.$emit('confirm')
                    } else {
                        this.errorMsg = ["Ingrese la palabra 'borrar' en este espacio."]
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
