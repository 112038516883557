<template>
    <section class="back-gris pad20">
        <div class="container-fluid">
            <div class="w1100 m-auto">
                <div class="text-center title">
                    <p class="subtitle">{{ content.title }}</p>
                </div>
                <div class="gFindus rgrid">
                    <!--TODO Poner slider?-->
                    <div v-for="link in content.links">
                        <a :href="link.url" :target="link.is_external ? '_blank': false">
                          <div class="box-left badge">
                              <img height="40" :src="link.logo">
                              <p>{{ link.name }}</p>
                          </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
    export default {
        name: "SplashFindus",
        props: ['content']
    }
</script>

<style scoped>
    .gFindus {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2rem;
    }

    p {
        color: #2B4959;
        font-size: 1.1rem;
    }

    .title {
        margin-bottom: 2rem;
    }

    .badge img {
        margin-right: 1rem;
        border-radius: 50%;
    }

    .badge p {
        flex: 100%;
        text-align: center;
        margin-right: calc(1rem + 40px);
        color: #3A3A3A;
    }

    .badge {
        background-color: #fff;
        border-radius: 4rem;
        padding: .5rem;

    }

    .badge p{
        margin-bottom: 0;
    }

    @media(max-width: 950px){
        .gFindus{
            grid-template-columns: 100%;
            grid-gap: 1rem;
        }

        .badge p{
            font-size: 1rem;
        }

        .title{
            margin-bottom:1rem;
        }
    }

</style>
