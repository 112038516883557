<template>
    <section>
        <nav class="box-left double-spaced">
            <a v-on:click="$emit('reroute', index, item.router)" v-for="(item, index) in links"
               :class="{active: activeTab===index }" :to="item.router" class="nav-item box-left spaced menu-item">
                <div class="icon-home"  v-if="index===0" :class="{'active':activeTab===0}">
                    <img class="default-icon" height="15" src="@/assets/icons/inicio.svg">
                    <img class="active-icon" height="15" src="@/assets/icons/inicio-act.svg">
                </div>
                <span>{{ item.label }}</span>
            </a>
        </nav>
    </section>
</template>

<script>
    export default {
        name: "NavClient",
        props: ['activeTab'],
        computed: {
          links: function() {
            let response = [
              {
                label: 'Inicio',
                router: '/inicio'
              }
            ];
            if (this.activeTab !== 4) {
              response = response.concat([
                {
                  label: 'Destacados',
                  router: '/destacados'
                },
                {
                  label: 'Géneros',
                  router: '/generos'
                },
                {
                  label: 'Décadas',
                  router: '/decadas'
                }
              ])
            }
            return response;
          }
        }
    }
</script>

<style scoped>
    a {
        cursor: pointer;
    }

    nav {
        padding: .5rem 1rem;
        display: flex;
    }

    .nav-item {
        padding: .5rem 1rem;
        border-radius: 10px;
        color: #333;
        border: 1px solid transparent;
    }

    .nav-item.active {
        border: 1px solid #BB1735;
    }

    .nav-item.active i {
        color: #BB1735;
    }

    .icon-home{
        position: relative;
        height: 15px;
        width: 15px;
        margin-right:.75rem;
    }

    .icon-home>img{
        position: absolute;
    }

    .icon-home .default-icon{
        opacity: 1;
    }

    .icon-home .active-icon{
        opacity: 0.01;
    }

    .icon-home.active .default-icon{
        opacity: 0.01;
    }

    .icon-home.active .active-icon{
        opacity: 1;
    }

    @media (max-width: 950px){
        nav{
            padding:0;
        }

        nav>a{
            margin-right: 0;
        }

        section{
            overflow-x: scroll;
        }

        section::-webkit-scrollbar{
            display: none;
        }

        .icon-home{
            display: none;
        }

        .menu-item{
            padding:.25rem .75rem;
        }

        .nav-item.active{
            background-color: #BB1735;
            color:#fff;
            border-radius: 2rem;
        }

        .nav-item.active span{
            color:#fff;
        }

        section{
            padding-top:.5rem;
            padding-bottom: .5rem;
        }
    }

</style>
