<template>
    <div class="box-center double-spaced">
        <a v-for="socialMedia in rrss" :href="socialMedia.link" class="rrss" target="_blank">
            <i :class="'fab ' + socialMedia.font_icon"></i>
        </a>
    </div>
</template>

<script>
    export default {
        props: ['rrss'],
        name: "Rrss",
    }
</script>

<style scoped>
    .rrss{
        box-shadow: 0 1px 4px rgba(0,0,0,0.25);
        width: 33px;
        height: 33px;
        border-radius:50%;
        background-color: #F3F4F6;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rrss i{
        color: #BB1735;
        font-size:1.1rem;
    }
</style>
