<template>
    <div>
        <div class="gTableHeader">
            <div class="text-center">Canción</div>
            <div class="text-center">Artista</div>
            <div class="text-center">Género</div>
            <div class="text-center">Derecho</div>
            <div class="text-center">Acciones</div>
        </div>
        <template v-for="item in records">
            <div class="gRecordItem">
                <div class="box-left double-spaced">
                    <div class="cover naui-overlay-container">
                        <img :src="item.cover">
                        <div class="naui-overlay">
                            <div class="box-center h-100 w-100">
                                <img height="25" src="@/assets/icons/play-lista.svg">
                            </div>
                        </div>
                    </div>

                    <div>
                        <router-link :to="{name: 'ClientSong', params:{id:item.id}}"><span>{{ item.title }}</span>
                        </router-link>
                        <div class="mobile artist">{{ item.artist }}</div>
                        <div class="mobile  genre">{{ item.genre }}</div>
                        <div class="mobile field record-rights">
                            <div v-if="item.fono" class="box-center record-right">
                                <img height="30" src="@/assets/icons/autoral.svg">
                                <span>Autoral {{ item.autoral }}</span>
                            </div>
                            <div class="box-center record-right">
                                <img height="30" src="@/assets/icons/fono.svg">
                                <span>Fono</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center desktop">
                    <span>{{ item.artist }}</span>
                </div>
                <div class="text-center desktop">
                    <span>{{ item.genre }}</span>
                </div>
                <div class="box-center desktop">
                    <div class="record-rights">
                        <div class="box-center record-right">
                            <img height="30" src="@/assets/icons/autoral.svg">
                            <span>Autoral {{ item.authoral }}</span>
                        </div>
                        <div v-if="item.fono" class="box-center record-right">
                            <img height="30" src="@/assets/icons/fono.svg">
                            <span>Fono</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="box-center actions">
                        <div class="action-item">
                            <img height="25" src="@/assets/icons/estrella.svg">
                        </div>
                        <div class="action-item" @click="addSong(item)"
                             style="cursor: pointer">
                            <img height="25" src="@/assets/icons/carrito.svg">
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import cart from "../../main";

    export default {
        name: "RelatesSongs",
        props: ['records'],
        data: () => {
            return {
                cart
            }
        },
        methods: {
            addSong: (song) => {
                this.cart.commit('add', song);
            }
        }
    }
</script>

<style scoped>
    a {
        cursor: pointer;
    }

    thead tr {
        background: linear-gradient(to right, #24404F 0%, #487E9C 100%);
    }

    .cover .naui-overlay {
        opacity: 0;
        transition: .2s;
        cursor: pointer;
    }

    .cover > img {
        border-radius: 4px;
    }

    .cover:hover .naui-overlay {
        opacity: 1;
    }

    .actions > div:first-child {
        border-right: 1px solid #eaeaea;
    }

    .actions > div {
        padding: 0 .5rem
    }

    .gTableHeader, .gRecordItem {
        grid-template-columns: 32% 20% 15% 18% 15%;
    }

    @media (max-width: 950px) {
        .desktop {
            display: none;
        }

        .gRecordItem {
            grid-template-columns: 120px 1fr;
            align-items: normal;
            grid-gap: .5rem;
            position: relative;
            border-bottom: 2px solid #eaeaea;
        }

        .gRecordItem > div:first-child, .gRecordItem > div:last-child {
            grid-column: 1/3;
        }

        .gRecordItem > div {
            padding: 0;
        }

        .gTableHeader {
            display: none;
        }

        .double-spaced > * {
            margin: 0;
        }

        .image {
            margin-right: 1rem;
            max-height: 80px !important;
        }

        .actions {
            justify-content: flex-end;
        }

        .duration {
            justify-content: flex-start;
        }

        .grid-rights {
            justify-content: flex-start;
        }

        .gRecordItem .duration {
            position: absolute;
            top: 100px;
            left: 40px;
            font-size: .8rem;
        }

        .main-item {
            align-items: flex-start;
            font-size: .9rem;
        }

        .main-item > * {
            align-self: flex-start;
        }

        .action-item img {
            height: 40px;
            padding: 5px;
            border: 1px solid #ccc;
            border-radius: 50%;
        }

        .action-item {
            opacity: 1;
            border: none !important;
            padding: 0 !important;
            margin-left: .75rem;
        }

        .record-right {
            min-width: 50px;
            margin-bottom: 0;
            padding: 0;
            bordeR: none;
            border-radius: 0;
        }

        .record-rights {
            flex-direction: unset;
        }

        .record-rights .record-right:nth-child(2) {
            border-left: 1px solid #ddd;
            margin-left: .5rem;
        }
    }

</style>
