<template>
    <section class="back-gris pad20">
        <div class="container-fluid regular">
            <div>
                <slick ref="slick" :options="slickOptions">
                    <div class="playlist" v-for="item in playlists">
                        <a :href="item.source" target="_blank">
                            <img class="w-100" :src="item.cover">
                        </a>
                        <div class="box-center extended">
                            <dd>{{ item.title }}</dd>
                            <a :href="item.source" target="_blank">
                                <i class="fab fa-spotify"></i>
                            </a>
                        </div>
                    </div>
                </slick>
            </div>
        </div>
    </section>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        name: "SplashPlaylists",
        components: {Slick},
        props: {
            playlists: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                slickOptions: {
                    slidesToShow: 5,
                    arrows: false,
                    autoplay: true,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 950,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                dots: true,
                            }
                        }
                    ]
                }
            }
        }

    }
</script>

<style scoped>
    .playlist img {
        margin-bottom: .5rem;
    }

    .playlist a {
        color: #333;
    }

    .fa-spotify {
        padding-right: .5rem;
        color: #81b71a;
        font-size: 2.5rem;
    }


</style>
