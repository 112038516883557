<template>
    <main>
        <simple-header :title="'Catálogos'" :link="true" back="/admin/categorias" ></simple-header>
        <section class="boton">
            <div class="container-fluid">
                <div @click="dialogActive.value = true" class="form-group box-left">
                    <div class="btn-form btn btn-rojo box-left spaced"><i class="fa fa-plus"></i><span> CREAR NUEVA SUBCATEGORÍA</span></div>
                </div>
            </div>
        </section>
        <sub-categorias :subcategories="subcategories"></sub-categorias>
        <form-aside-new v-on:saved="newSubCat" :active="dialogActive" object="subcategory" :cat-id="$route.params.id"></form-aside-new>
    </main>
</template>

<script>
    import AdminHeader from "./partials/AdminHeader";
    import SubCategorias from "./partials/SubCategorias";
    import FormAsideNew from "../../components/forms/FormAsideNew";
    import SimpleHeader from "../../components/SimpleHeader";

    export default {
        name: "AdminCategory",
        components: {SimpleHeader, FormAsideNew, SubCategorias, AdminHeader},
        data: () => ({
            subcategories: [],
            dialogActive: {value: false},
            newObject: {
                name: '',
                img: [],
                slug: '',
                highlight: false,
                is_active: false
            }
        }),
        mounted() {
            this.refresh()
        },
        methods: {
            newSubCat: function (response) {
                this.dialogActive.value = false;
                this.refresh()
            },
            refresh: function () {
                var self = this;
                this.$axios.get(`admin-app/categorias/${this.$route.params.id}`)
                    .then(function (response) {
                        self.subcategories = response.data;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            }
        }
    }
</script>

<style scoped>
    .title {
        margin-bottom: .75rem;
    }

    .title img {
        margin-right: .75rem;
    }

    .body {
        margin: 1rem;
    }

    .boton{
        margin-top: 1rem;
    }
</style>
