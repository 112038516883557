<template>
  <main>
    <simple-header :title="header.name" :link="true"
                   :back="`/admin/categorias/${$route.params.id}`"></simple-header>

    <div class="subHeader">
      <div class="subInfo">
        <v-select
            v-on:change="$emit('save')"
            v-model="header.is_active"
            :items="statusOptions"
            label="Estado"
        ></v-select>
        <v-file-input
            label="Imagen de la carátula"
            filled
            prepend-icon="mdi-camera"
        ></v-file-input>
      </div>
      <v-checkbox v-model="header.highlight" label="Destacar en home"></v-checkbox>
      <div class="btn btn-rojo box-center" @click="save()">
        <span>GUARDAR</span>
      </div>
    </div>
    <admin-record-table v-on:change="getSongs" :subcat="true" :iniRecords="records"
                        :pagination="pagination"></admin-record-table>
    <v-snackbar v-model="updateSnackbar" :timeout="4000">
      Cambios guardados
      <v-btn color="blue" text @click="updateSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </main>
</template>

<script>
import AdminRecordTable from "../../components/tables/AdminRecordTable";
import AdminHeader from "./partials/AdminHeader";
import SimpleHeader from "../../components/SimpleHeader";

export default {
  name: "AdminCategoryInterna",
  components: {SimpleHeader, AdminHeader, AdminRecordTable},
  data: () => ({
    statusOptions: [
      {
        text: 'Activo',
        value: true
      },
      {
        text: 'No activo',
        value: false
      },
    ],
    header: {
      id: '',
      name: '',
      slug: '',
      highlight: false,
      is_active: true,
      img: []
    },
    updateSnackbar: false,
    records: [],
    pagination: {page: 1, pages: 1}
  }),
  mounted() {
    var self = this;
    this.$axios.get(`admin-app/subcategoria/${this.$route.params.slug}/header`)
        .then(function (response) {
          self.header = response.data.details;
        })
        .catch(function (error) {
          self.$utils.validate(error.response, self)
        });
    this.getSongs();
  },
  methods: {
    save: function () {
      var self = this;
      var formData = new FormData();
      var uploadObject = {...this.header};
      delete uploadObject['img'];
      formData.append("file", this.header.img);
      formData.append("data", JSON.stringify(uploadObject));
      this.$axios.post(`admin-app/subcategoria/${this.$route.params.slug}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(function (response) {
            self.updateSnackbar = true;
          })
          .catch(function (error) {
            if (error.response.status === 409) {
              if (error.response.data.error_name) self.errorMessages.name = [error.response.data.error_name];
              if (error.response.data.error_slug) self.errorMessages.slug = [error.response.data.error_slug];
            }
            self.$utils.validate(error.response, self);
          })
    },
    getSongs: function (inputSearch) {
      var self = this;
      this.$axios.get(`admin-app/subcategoria/${this.$route.params.slug}`,
          {
            params: {
              p: self.pagination.page
            }
          })
          .then(function (response) {
            self.records = response.data.songs;
            self.pagination = response.data.pagination;
          })
          .catch(function (error) {
            self.$utils.validate(error.response, self)
          })
    }
  }
}
</script>

<style scoped>
.subInfo {
  display: grid;
  grid-template-columns: 400px 270px 1fr;
  align-items: center;
  grid-gap: 2rem;
}

@media(max-width: 1024px){
  .subInfo{
    grid-template-columns: 1fr;
    grid-gap:1rem;
  }
}

.title {
  margin-bottom: .75rem;
}

.title img {
  margin-right: .75rem;
}

.subHeader {
  margin: 1rem;
}
</style>
