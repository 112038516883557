<template>
    <div class="text-center">
        <v-dialog v-model="dialog.value" max-width="500px">
            <v-card class="pad card-wrap">
                <v-form ref="form">
                    <div>
                        <img class="logo" height="50" src="../../assets/pix/landing/logo.png" alt="logo">
                    </div>
                    <div>
                        <h2>{{headline}}</h2>
                        <p>{{subtitle}}</p>
                    </div>
                    <div>
                        <v-text-field :rules="[rules.required]" v-model="profile.name"
                                      label="Nombre Completo"
                                      required></v-text-field>
                        <div class="grid13 gap10">
                            <div>
                                <v-select :rules="[rules.required]" v-model="profile.id_type"
                                          :items="items"
                                          value="CC"></v-select>
                            </div>
                            <div>
                                <v-text-field :rules="[rules.required]" :error-messages="errors.doc"
                                              v-model="profile.id_num"
                                              label="Documento de identidad"></v-text-field>
                            </div>
                        </div>
                        <div class="grid2 gap10">
                            <div>
                                <v-text-field :rules="[rules.required]" v-model="profile.email"
                                              :error-messages="errors.email"
                                              label="Correo electronico"
                                ></v-text-field>
                            </div>
                            <div>
                                <v-text-field :rules="[rules.required]" v-model="profile.phone"
                                              label="Teléfono de contacto"></v-text-field>
                            </div>
                        </div>
                        <v-text-field :rules="[rules.required]" v-model="profile.workplace"
                                      label="Empresa"></v-text-field>
                        <v-text-field :rules="[rules.required]" v-model="profile.username"
                                      :error-messages="errors.username"
                                      label="Usuario"></v-text-field>
                        <v-select v-if="admin" :rules="[rules.required]" v-model="profile.role"
                                  :items="rolItems"></v-select>
                        <v-text-field v-if="admin" :rules="[rules.required]" :error-messages="errors.pass"
                                      v-model="profile.password" label="Contraseña"
                                      type="password" required></v-text-field>
                        <v-text-field v-if="admin" :rules="[rules.required]" :error-messages="errors.pass"
                                      v-model="confirmPass"
                                      label="Confirmar contraseña" type="password" required></v-text-field>
                    </div>
                    <div class="text-center box-center pad10">
                        <div class="btn btn-rojo" @click="newUser">{{button}}</div>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
        <div v-if="admin">
            <v-snackbar v-model="createdDialog" :timeout="4000">
                Usuario creado
                <v-btn color="blue" text @click="createdDialog = false">Cerrar</v-btn>
            </v-snackbar>
        </div>
        <div v-else>
            <information-dialog text="Tu cuenta será revisada para que posteriormente puedas ingresar"
                                title="Registro exitoso"
                                :dialog="createdDialog"
                                v-on:ok="$router.push('/login')"></information-dialog>
        </div>
    </div>
</template>

<script>
    import InformationDialog from "../dialogs/InformationDialog";

    export default {
        name: "FormRegistro",
        components: {InformationDialog},
        props: ['dialog', 'admin'],
        data: () => ({
            items: [],
            rolItems: [{text: 'Cliente', value: 0}, {text: 'Interno', value: 1}],
            profile: {
                name: '',
                id_type: 'cc',
                id_num: '',
                email: '',
                phone: '',
                workplace: '',
                username: '',
                password: '',
                role: 0
            },
            confirmPass: '',
            rules: {
                required: value => !!value || 'Este campo es requerido.'
            },
            errors: {
                pass: []
            },
            createdDialog: false,
        }),
        mounted() {
            const self = this;
            this.$axios.get('admin-app/id-types')
                .then(function (response) {
                    self.items = response.data;
                })
        },
        methods: {
            newUser: function () {
                if (this.$refs.form.validate()) {
                    if (this.profile.password === this.confirmPass) {
                        this.errors.pass = [];
                        const self = this;
                        this.$axios.post(this.url, {
                            profile: this.profile
                        })
                            .then(function (response) {
                                self.dialog.value = false;
                                self.createdDialog = true;
                            })
                            .catch(function (error) {
                                self.errors = error.response.data;
                            })

                    } else {
                        this.errors.pass = ['Las contraseñas no coinciden.']
                    }
                }
            }
        },
        computed: {
            url: function () {
                if (this.admin) {
                    return 'admin-app/usuarios/crear-usuario'
                } else {
                    return 'registro'
                }
            },
            headline: function () {
                if (this.admin) {
                    return 'Crear usuario'
                } else {
                    return 'Contáctenos'
                }
            },
            subtitle: function () {
                if (this.admin) {
                    return 'Ingrese los datos del nuevo usuario'
                } else {
                    return 'Contáctenos para acceder a nuestro catálogo completo'
                }
            },
            button: function () {
                if (this.admin) {
                    return 'Crear usuario'
                } else {
                    return 'ENVIAR'
                }
            },
        }
    }
</script>

<style scoped>
    .card-wrap{
        padding:2rem;
    }

    .logo{
        margin-bottom: 1rem;
    }

    @media(max-width: 700px){
        .grid13, .grid2{
            display: block;
        }
    }

</style>
