<template>
    <main>
        <admin-search :user-actions="true" total="0" :search="search" :total="count.value" v-on:newUserButton="newUserDialog.value = true"></admin-search>
        <div class="subHeader">
            <filter-widget :filter-options="filterOptions" :filter-values="filterValues"></filter-widget>
        </div>
        <users-table :search="search" :filters="filterValues" :count="count"></users-table>
        <form-registro :dialog="newUserDialog" :admin="true"></form-registro>
    </main>
</template>

<script>
    import AdminHeader from "./partials/AdminHeader";
    import AdminSearch from "./partials/AdminSearch";
    import FilterWidget from "../../components/widgets/FilterWidget";
    import UsersTable from "../../components/UsersTable";
    import FormRegistro from "../../components/forms/FormRegistro";

    export default {
        name: "AdminUsers",
        components: {FormRegistro, UsersTable, FilterWidget, AdminSearch, AdminHeader},
        data: () => ({
            search: {value: ''},
            filterOptions: {
                perfil: [
                    {text: 'Interno',
                    value:1},
                    {text: 'Cliente',
                    value:0}
                ]
            },
            filterValues: {
                perfil: ''
            },
            count: {value:0},
            newUserDialog: {value: false}
        })
    }
</script>

<style scoped>
    .subHeader {
        margin-left: 1rem;
    }

</style>
