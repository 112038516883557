<template>
    <section>
        <table class="tabla w700">
            <thead>
            <tr>
                <td class="forced-padding" colspan="3"></td>
            </tr>
            <tr>
                <td class="text-left">Nombre</td>
                <td class="text-center" style="width: 200px">Canciones</td>
                <td class="text-center" style="width: 200px">Acciones</td>
            </tr>
            <tr>
                <td class="forced-padding" colspan="3"></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in listas">
                <td class="text-left">
                    <span>{{ item.name }}</span>
                </td>
                <td class="text-center">
                    <span>{{ item.count }}</span>
                </td>
                <td>
                    <div class="box-center actions double-spaced">
                        <router-link :to="{name:'AdminListaInterna', params: {id:item.id}}">
                            <img height="25" src="@/assets/icons/listas-lupa.svg">
                        </router-link>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    export default {
        name: "Listas",
        props: ['listas']
    }
</script>

<style scoped>

</style>
