<template>
    <section class="pad30">
        <div class="container-fluid regular">
            <div class="gDerechos rgrid">
                <div>
                    <h2>{{ content.title1 }}</h2>
                    <p>{{ content.text1 }}</p>
                </div>
                <div>
                    <h3 class="box-left spaced">
                        <img height="20" src="@/assets/pix/landing/autoral.svg">
                        <span>
                        {{ content.title2 }}
                    </span>
                    </h3>
                    <p>{{ content.text2 }}</p>
                </div>
                <div>
                    <h3 class="box-left spaced">
                        <img height="20" src="@/assets/pix/landing/fono.svg">
                        <span>
                        {{ content.title3 }}
                            </span>
                    </h3>
                    <p>{{ content.text3 }}</p>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: "SplashDerechos",
        props: ['contentData'],
        data() {
            return {
                content: {
                    title1: 'Música con derechos acorde \n a su necesidad',
                    text1: 'Sea para publicidad en youtube, internet, radio, una producción de TV o cine, contamos con un catálogo que ofrece los derechos con los que su producción debe contar.',
                    title2: 'Derechos Autorales',
                    text2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget sagittis odio. Praesent porta ex lectus, vitae sollicitudin dui egestas vel. Phasellus semper consectetur.',
                    title3: 'Derechos Fonográficos',
                    text3: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget sagittis odio. Praesent porta ex lectus, vitae sollicitudin dui egestas vel. Phasellus semper consectetur.'
                }
            }
        },
        mounted() {
            this.content.title1 = this.contentData.banner_title1
            this.content.text1 = this.contentData.banner_text1
            this.content.text2 = this.contentData.authoral_text
            this.content.text3 = this.contentData.fono_text
        }
    }
</script>

<style scoped>
    section {
        background-color: #24404F;
        color: #fff;
    }

    .gDerechos {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .gDerechos > div {
        padding: .5rem 2rem;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    h3, p, dt {
        color: #fff;
    }

    .gDerechos > div:last-child {
        border-right: none;
        padding-right: 0;
    }

    .gDerechos > div:first-child{
        padding-left: 0;
    }

    h2{
        margin-bottom: 1rem;
        font-weight: 600;
        line-height: 1.2;
    }

    h3{
        margin-bottom: 1rem;
    }

    @media(max-width: 800px){
        .gDerechos{
            grid-template-columns: 100%;
        }

        .gDerechos > div{
            border-right: none;
            padding-left:0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }

        .gDerechos > div:last-child {
            border-bottom: 0;
        }

        section.pad30{
            padding-top:1.5rem;
            padding-bottom:1.5rem;
        }
    }

</style>
