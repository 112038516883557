export const utils = {
    addIfIdUnique: function (arr, song) {
        const found = arr.some(el => el.id === song.id);
        if (!found) arr.push(song);
        return arr;
    },
    quotation: function (newSongs) {
        var self = this;
        var songs = JSON.parse(localStorage.getItem('quoteSongs')) || [];
        newSongs.forEach(function (song) {
            songs = self.addIfIdUnique(songs, song);
        });
        localStorage.setItem('quoteSongs', JSON.stringify(songs));
        return songs;
    },
    logout: function () {
        localStorage.setItem('token', '')
    },
    validate: function (response, vue) {
        if(response.status===401) {
            vue.$router.push('/login');
            localStorage.removeItem('token');
        }
    },
    slug: function(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        var to   = "aaaaaeeeeeiiiiooooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }
}
