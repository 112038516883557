<template>
    <div>
        <aside class="action-overlay" :class="{active: active.value}">
            <div class="header">
                <div v-if="!createList" class="box-center double-spaced">
                    <img height="17" src="@/assets/icons/agregar-blanco.svg">
                    <span>Agregar canciones a una lista</span>
                </div>
                <div v-if="createList" class="box-center">
                    <span>Crear nueva lista</span>
                </div>
            </div>
            <div class="body">
                <div v-if="!createList" class="block">
                    <dt>Selecciona la lista de tu preferencia</dt>
                    <dd v-for="item in listas" @click="saveSongs(item.id)">{{ item.name }}</dd>
                </div>
                <div v-if="createList" class="block">
                    <div class="block">
                        <div class="form-group">
                            <v-text-field v-model="newPlaylist" :rules="[rules.required]" label="Nombre de la lista"/>
                        </div>
                        <br>
                        <div @click="createPlaylist" class="form-group box-center">
                            <div class="btn-form btn btn-rojo">GUARDAR</div>
                        </div>
                        <div class="form-group box-center" @click="createList=false">
                            <div class="btn-form btn btn-outline">CANCELAR</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!createList" class="actions">
                <div class="block box-center">
                    <div class="btn btn-rojo box-left spaced" @click="createList=true">
                        <img height="15" src="@/assets/icons/plus.svg">
                        <span>CREAR NUEVA LISTA</span>
                    </div>
                </div>
            </div>
        </aside>
        <div class="back-overlay" :class="{active: active.value}" @click="active.value=false"></div>
        <v-snackbar v-model="createdSnackbar" :timeout="4000">
            Playlist creada
            <v-btn color="blue" text @click="createdSnackbar = false">Cerrar</v-btn>
        </v-snackbar>
        <v-snackbar v-model="doneDialog" :timeout="4000">
            Canciones agregadas a lista
            <v-btn color="blue" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import InformationDialog from "./dialogs/InformationDialog";
    export default {
        components: {InformationDialog},
        props: ['active', 'records'],
        name: "AgregarLista",
        data: () => ({
            createList:false,
            newPlaylist: '',
            doneDialog: false,
            listas: [],
            rules: {
                required: value => !!value || 'Requerido.'
            },
            createdSnackbar: false,
        }),
        mounted() {
            this.getPlaylists()
        },
        methods: {
            done: function () {
                this.doneDialog = false;
                this.active.value = false;
            },
            getPlaylists: function () {
                var self = this;
                this.$axios.get(`/playlists-min`)
                    .then(function (response) {
                        self.listas = response.data.playlists;
                    })
                    .catch(function (error) {
                        // self.$utils.validate(error.response, self)
                    });
            },
            saveSongs: function (id, song) {
                var sendRecords = [];
                if(song === undefined) {
                    this.records.value.forEach(function (song) {
                        sendRecords.push(JSON.parse(song).id)
                    });
                }
                else {
                    sendRecords.push(song.id)
                }
                var self = this;
                this.$axios.post('anadir-a-playlist', {playlist_id: id, song_ids: sendRecords})
                    .then(function (response) {
                        self.doneDialog = true;
                        self.active.value = false;
                    })
                    .catch(function (error) {
                        // self.$utils.validate(error.response, self)
                    });
            },
            createPlaylist: function () {
                if(this.newPlaylist !== '') {
                    var self = this;
                    this.$axios.post('crear-playlist', {name: this.newPlaylist})
                        .then(function (response) {
                            if(response.data.status === 201) {
                                self.createList = false;
                                self.listas.push({id: response.data.id, name: self.newPlaylist})
                                self.createdSnackbar = true;
                            }
                        })
                        .catch(function (error) {
                            // self.$utils.validate(error.response, self)
                        });
                }
            }
        }
    }
</script>

<style scoped>
    .action-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 350px;
        background-color: #fff;
        z-index: 99;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        transform: translateX(100%);
        transition: 0.2s all ease-in-out;
    }

    .action-overlay.active {
        transform: translateX(0%);
    }

    .back-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        z-index: 98;
    }

    .back-overlay.active {
        bottom: 0;
        opacity: 1;
    }

    .header {
        background-color: #2B4959;
        padding: 1.5rem 1rem;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 600;
    }

    dd, dt {
        border-bottom: 1px solid #eaeaea;
        padding: 1rem 0;
    }

    dd {
        color: #5788A8;
        cursor: pointer;
        padding-left: .5rem;
        transition: .2s;
    }

    dd:hover {
        background-color: #f7f8f9;
    }

    .action-overlay {
        display: flex;
        flex-direction: column;
    }

    .action-overlay .body {
        flex: 100%;
    }

    .actions .block {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .btn-form {
        width: 200px;
        text-align: center;
    }

    @media(max-width: 600px){
        .action-overlay{
            width: 290px;
        }

        .header{
            line-height: 1.2;
        }
    }

</style>
