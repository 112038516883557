<template>
    <main>
        <simple-header :title="'Cargar canción'" :back="'/admin/catalogo'"></simple-header>
        <section>
            <form-new-song></form-new-song>
        </section>
    </main>
</template>

<script>
    import FormNewSong from "../../components/forms/FormNewSong";
    import SimpleHeader from "../../components/SimpleHeader";

    export default {
        name: "AdminUpload",
        components: {SimpleHeader, FormNewSong}
    }
</script>

<style scoped>

</style>
