<template>
        <header class="gHeader naui-shadow">
            <div class="logo">
                <router-link :to="{name: 'ClientMain'}">
                    <img height="50" src="../assets/pix/landing/logo.png">
                </router-link>
            </div>
            <div class="search">
                <div class="search-wrap">
                    <input class="naui-input search-input" v-model="inputSearch" placeholder="Buscar"
                           v-on:keyup="debouceFunc">
                    <img height="18" class="lupa" src="@/assets/icons/lupa.svg" v-if="inputSearch.length === 0"
                         @click="search">
                    <img height="18" class="lupa" src="@/assets/icons/vaciar-busqueda.svg" v-else
                         @click="reset()">
                    <div class="results" v-if="results.songs.length > 0 || results.artists.length > 0">
                        <ul v-if="results.artists.length > 0">
                          <li  @click="goArtistRoute(artist.id)" v-for="artist in results.artists">
                            <a>{{artist.nombre}}</a>
                          </li>
                        </ul>
                        <hr v-if="results.songs.length > 0 && results.artists.length > 0">
                        <ul v-if="results.songs.length > 0">
                            <li  @click="goSongRoute(result.id)" v-for="result in results.songs">
                                <a>{{result.titulo}}</a>
                                <a class="findin">{{result.encontrado}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="box-center menu-items">
                    <router-link to="/listas" class="box-left menu-item">
                        <img height="25" src="@/assets/icons/mis-listas.svg">
                        <span>Mis listas</span>
                    </router-link>
                    <router-link to="/perfil" class="box-left menu-item">
                        <img height="25" src="@/assets/icons/perfil.svg">
                        <span>Mi Perfil</span>
                    </router-link>
                    <router-link :to="{name: 'ClientQuote'}" class="box-left menu-item">
                        <template>
                            <img height="25" src="@/assets/icons/carrito.svg">
                            <span>
                            Cotización
                            <i class="fas fa-circle dot" v-if="show"></i>
                        </span>
                        </template>
                    </router-link>
                </div>
            </div>
            <div class="logout">
                <Logout></Logout>
            </div>


        </header>
</template>

<script>
    import Logout from "./Logout";
    import cart from "../main";

    export default {
        name: "HeaderClient",
        components: {Logout},
        data: function () {
            return {
                inputSearch: '',
                results: {
                  songs: [],
                  artists: []
                },
                cart
            }
        },
        mounted() {
        },
        methods: {
            debouceFunc: function () {
                // clears the timer on a call so there is always x seconds in between calls
                clearTimeout(this.timer);

                // if the timer resets before it hits 150ms it will not run
                this.timer = setTimeout(function () {
                    this.search()
                }.bind(this), 600);
            },
            goSongRoute: function(id) {
                this.$router.push(`/cancion/${id}`);
                this.reset();
            },
            goArtistRoute: function(id) {
                this.$router.push(`/artistas/${id}`);
                this.reset();
            },
            reset: function() {
                // this.$router.push(this.$route.path);
                this.inputSearch = '';
                this.results = {
                  songs: [],
                  artists: []
                };
            },
            search: function () {
                if (this.inputSearch.length !== 0) {
                    this.$router.push(this.$route.path + '?s=' + this.inputSearch);
                    const self = this;
                    this.$axios.post('search-bar', {'q': this.inputSearch})
                        .then(function (response) {
                            self.results = response.data;
                        })
                        .catch(function (error) {
                            self.$utils.validate(error.response, self)
                        })
                } else {
                    this.reset()
                }
            }
        },
        computed: {
            show: function () {
                return this.cart ? this.cart.state.songs.length > 0 : false;
            },
            len: function () {
                this.cart ? `${this.cart.state.songs.length}` : '';
            }
        }
    }
</script>

<style scoped>
    header {
        position: sticky;
        top: 0;
        z-index: 5;
        background-color: #fff;
    }

    .gHeader {
        display: grid;
        align-items: center;
        grid-gap: 2rem;
        grid-template-columns: auto 1fr auto auto;
        padding: .75rem 1rem;
    }

    .menu-items {
        display: flex;
        justify-content: space-around;
    }

    .menu-items > * {
        /*cursor: pointer;*/
        margin-left: .5rem;
        margin-right: .5rem;
    }

    .menu-item img {
        margin-right: .25rem;
    }

    .menu-item {
        padding: .2rem .5rem;
        border-radius: 1rem
    }

    .menu-item:active {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .search-wrap {
        position: relative;
    }

    .search-wrap .lupa {
        position: absolute;
        right: 1rem;
        top: .65rem;
        color: #487E9C;
        cursor: pointer;
    }

    .dot {
        font-size: .9rem;
        margin-left: .2rem;
        color: #B81432 !important;
    }

    .results{
        position:absolute;
        background-color:#fff;
        width:100%;
        border-radius:10px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.1);
    }

    .results ul{
        list-style-type: none;
        padding:.5rem;
    }

    .results ul li{
        line-height: 2;
        cursor: pointer;
        padding:0 .5rem;
        border-radius:4px;
    }

    .results ul li:hover{
        background-color: #f4f4f4;
    }

    .findin{
        margin-left:.5rem;
        border-left:1px solid #eaeaea;
        padding-left:.5rem;
        font-size: .8rem;
    }

    @media(max-width: 950px){
        .gHeader{
            grid-template-areas: 'logo logout''search search''actions actions';
            grid-gap: .5rem;
            grid-template-columns: 1fr 1fr;
        }

        .gHeader .logo{
            grid-area: logo;
        }

        .gHeader .logo img{
            height:40px;
        }

        .gHeader .search{
            grid-area: search;
        }

        .gHeader .actions{
            grid-area: actions;
        }

        .gHeader .logout{
            grid-area: logout;
            display: flex;
            justify-content: flex-end;
        }

        .menu-item{
            margin: 0;
            font-size: .9rem;
        }

        .menu-item img{
            height:20px;
        }

        .dot{
            font-size: 7px;
            position: relative;
            top:-2px;
        }

        header{
            top:-50px;
        }

    }

</style>
