<template>
    <div class="text-center">
        <v-card>
            <v-form ref="form" class="song-form">
                <div class="text-left block">
                    <p>Ingrese los datos del album y la canción</p>
                </div>
                <div class="title block">
                    <h3 class="text-left">Album</h3>
                </div>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-autocomplete
                                    v-model="song.album"
                                    :items="albumItems"
                                    label="Album"
                                    :clearable="true"
                                    messages="Seleccione un album o ingrese los datos para crear uno nuevo"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row v-if="song.album===undefined">
                        <v-col cols="12">
                            <v-text-field :rules="[rules.required]" v-model="song.title_album"
                                          label="Título album"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :rules="[rules.required]"
                                            v-model="song.artist"
                                            :items="artistItems"
                                            label="Artista"
                                            required
                                            multiple
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field :rules="[rules.required]" v-model="song.record_label"
                                          label="Sello"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field :rules="[rules.required]" v-model="song.reference"
                                          label="Referencia"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field :rules="[rules.required]" v-model="song.upc"
                                          label="UPC"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-file-input
                                    v-model="song.cover_album"
                                    label="Cover"
                                    accept="image/*"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <div class="title block">
                        <h3 class="text-left">Canción</h3>
                    </div>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-text-field :rules="[rules.required]" v-model="song.tracknum"
                                          label="#"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <v-text-field :rules="[rules.required]" v-model="song.title_song"
                                          label="Título tema musical"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :rules="[rules.required]"
                                            v-model="song.author"
                                            :items="authorItems"
                                            label="Autor (es)"
                                            required
                                            multiple
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field :rules="[rules.required]" v-model="song.genre"
                                          label="Género"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="song.release_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="song.release_date"
                                                  label="Fecha de lanzamiento"
                                                  readonly
                                                  v-on="on" :rules="[rules.required]"></v-text-field>
                                </template>
                                <v-date-picker v-model="song.release_date" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(song.release_date)">OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field :rules="[rules.required]" v-model="song.isrc"
                                          label="ISRC"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-checkbox v-model="song.authoral"
                                        label="Autoral"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <v-text-field :rules="[rules.required]" v-model="song.authoral_percentage"
                                          label="% autoral"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field :rules="[rules.required]" v-model="song.fono_territory"
                                          label="Territorio Fonograma"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="song.authoral_territory"
                                          label="Territorio Autoral"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                    label="Letra de la canción"
                                    v-model="song.lyrics"
                            ></v-textarea>
                        </v-col>
                        <v-col>
                            <v-radio-group v-model="choiceFile">
                                <v-radio label="Escribir nombre" :value="0"></v-radio>
                                <v-radio label="Subir archivo" :value="1"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col v-if="choiceFile===0">
                            <p>En caso de que el archivo no se haya subido todavía seleccionar 'Subir
                                archivo'
                            </p>
                        </v-col>
                        <v-col cols="12" v-if="choiceFile===0">
                            <v-text-field :rules="[rules.required]" v-model="song.file_name"
                                          label="Nombre de archivo"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-else>
                            <v-file-input
                                    v-model="song.file"
                                    label="Archivo"
                                    accept="audio/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="text-center pa-2">
                    <div class="btn btn-rojo" @click="newSong">CARGAR</div>
                </div>
            </v-form>
        </v-card>
        <information-dialog text="La canción fue creada correctamente"
                            title="Canción creada"
                            :dialog="dialog"
                            v-on:ok="$router.push('/admin/catalogo')"></information-dialog>
    </div>
</template>

<script>
    import InformationDialog from "../dialogs/InformationDialog";

    export default {
        name: "FormNewSong",
        components: {InformationDialog},
        data: () => ({
            albumItems: [],
            menu: false,
            choiceFile: 0,
            dialog: false,
            song: {
                record_label: '',
                reference: '',
                upc: '',
                artist: '',
                title_album: '',
                tracknum: '',
                title_song: '',
                author: '',
                isrc: '',
                release_date: '',
                authoral: false,
                authoral_percentage: '',
                authoral_territory: '',
                fono_territory: '',
                genre: '',
                lyrics: '',
                file: [],
                file_name: '',
                album: undefined,
                cover_album: []
            },
            rules: {
                required: value => !!value || 'Este campo es requerido.'
            },
            errors: {
                pass: []
            },
            createdDialog: false,
            artistItems: [],
            authorItems: [],
        }),
        mounted() {
            const self = this;
            this.$axios.get('admin-app/id-types')
                .then(function (response) {
                    self.items = response.data;
                });
            this.$axios.get('admin-app/album-all')
                .then(function (response) {
                    self.albumItems = response.data;
                });
            this.$axios.get('admin-app/artist-all')
                .then(function (response) {
                    self.artistItems = response.data;
                });
            this.$axios.get('admin-app/author-all')
                .then(function (response) {
                    self.authorItems = response.data;
                });
        },
        methods: {
            newSong: function () {
                if (this.$refs.form.validate()) {
                    const self = this;
                    var formData = new FormData();
                    var uploadObject = {...this.song};
                    delete uploadObject['cover_album'];
                    delete uploadObject['file'];
                    formData.append("cover_album", this.song.cover_album);
                    formData.append("song_file", this.song.file);
                    formData.append("data", JSON.stringify(this.song));
                    this.$axios.post(`admin-app/cancion/cargar`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(function (response) {
                            self.dialog = true;
                        })
                        .catch(function (error) {
                            if (error.response.status === 409) {
                                if (error.response.data.error_name) self.errorMessages.name = [error.response.data.error_name];
                                if (error.response.data.error_slug) self.errorMessages.slug = [error.response.data.error_slug];
                            } else {
                              if (error.response.data.message) alert(error.response.data.message);
                            }
                            self.$utils.validate(error.response, self);
                        })
                }
            }
        },
        computed: {}
    }
</script>

<style scoped>
    form.v-form {
        padding-left: 15%;
        padding-right: 15%;
        text-align: -webkit-center;
    }

    .song-form{
        padding-bottom:4rem;
    }

    .title.block{
        padding:0 1rem;
    }
</style>
