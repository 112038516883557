<template>
    <div>
        <div class="gTableHeader">
            <div class="text-center">Canción</div>
            <div class="text-center">Artista</div>
            <div class="text-center">Género</div>
            <div class="text-center">Derechos requeridos</div>
            <div v-if="!readonly" class="text-center">Acciones</div>
        </div>
        <template v-for="item in records">
            <div class="gRecordItem">
                <div class="box-left double-spaced main-item">
                    <div class="cover naui-overlay-container">
                        <img class="image" style="max-height: 60px;" :src="item.cover">
                        <div class="naui-overlay">
                            <div class="box-center h-100 w-100">
                                <img height="25" src="@/assets/icons/play-lista.svg" v-if="showPlay(item)"
                                     @click="play(item)">
                                <img height="25" src="@/assets/icons/pausa.svg" v-if="showPause(item)"
                                     @click="pause(item)">
                            </div>
                        </div>
                    </div>
                    <div>
                        <span>{{ item.title }}</span>
                        <div class="mobile artist">{{ item.artist }}</div>
                        <div class="mobile  genre">{{ item.genre }}</div>
                        <div class="mobile field record-rights">
                            <div v-if="item.fono" class="box-center record-right">
                                <img height="30" src="@/assets/icons/autoral.svg">
                                <span>Autoral {{ item.autoral }}</span>
                            </div>
                            <div class="box-center record-right">
                                <img height="30" src="@/assets/icons/fono.svg">
                                <span>Fono</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center desktop">
                    <span>{{ item.artist }}</span>
                </div>
                <div class="text-center desktop">
                    <span>{{ item.genre }}</span>
                </div>
                <div class="box-center desktop">
                    <div class="record-rights">
                        <div v-if="item.fono" class="box-left record-right">
                            <input v-if="!readonly" type="checkbox" v-model="item.r_fono">
                            <img height="30" src="@/assets/icons/autoral.svg">
                            <span>Autoral {{ item.autoral }}</span>
                        </div>
                        <div class="box-left record-right">
                            <label class="box-left">
                                <img height="30" src="@/assets/icons/fono.svg">
                                <span>Fono</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="!readonly">
                    <div class="box-center actions">
                        <div class="action-item trash" @click="$emit('remove', item)" style="cursor: pointer">
                            <img height="17" src="@/assets/icons/trash-alt.svg">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-if="records.length < 1 && !readonly" class="text-center">
            <h1>No hay canciones agregadas</h1>
            <p>Agrega canciones aquí</p>
            <router-link :to="{name: 'ClientMain'}" class="form-group box-center">
                <div class="btn-form btn btn-rojo">Agregar</div>
            </router-link>
        </div>
    </div>


</template>

<script>
    export default {
        name: "QuoteSongs",
        props: ['readonly', 'records'],
        data: () => {
            return {
                player: null,
                current: null,
                playing: false
            }
        },
        beforeDestroy() {
            this.pause();
        },
        methods: {
            play: function (song) {
                if (this.player) {
                    this.player.pause();
                }
                if (this.current && this.player && this.current.id === song.id) {
                    this.player.play();
                } else {
                    this.current = song;
                    this.player = new Audio(song.song);
                    this.player.play();
                }
                this.playing = true;
            },
            pause: function (song) {
                if (this.player) {
                    this.player.pause();
                }
                this.playing = false;
            },
            showPlay: function (song) {
                if (this.current) {
                    return this.current.id !== song.id || (!this.playing && this.current.id === song.id);
                } else {
                    return true;
                }
            },
            showPause: function (song) {
                if (this.current) {
                    return this.current.id === song.id && this.playing;
                } else {
                    return false;
                }
            }
        }
    }
</script>

<style scoped>
    .gTableHeader, .gRecordItem {
        grid-template-columns: 33% 20% 15% 20% 1fr;
    }

    @media (max-width: 950px) {
        .desktop {
            display: none;
        }

        .gRecordItem {
            grid-template-columns: 120px 1fr;
            align-items: normal;
            grid-gap: .5rem;
            position: relative;
            border-bottom: 2px solid #eaeaea;
        }

        .gRecordItem > div:first-child, .gRecordItem > div:last-child {
            grid-column: 1/3;
        }

        .gRecordItem > div {
            padding: 0;
        }

        .gTableHeader {
            display: none;
        }

        .double-spaced > * {
            margin: 0;
        }

        .image {
            margin-right: 1rem;
            max-height: 80px !important;
        }

        .actions {
            justify-content: flex-end;
        }

        .duration {
            justify-content: flex-start;
        }

        .grid-rights {
            justify-content: flex-start;
        }

        .gRecordItem .duration {
            position: absolute;
            top: 100px;
            left: 40px;
            font-size: .8rem;
        }

        .main-item {
            align-items: flex-start;
            font-size: .9rem;
        }

        .main-item > * {
            align-self: flex-start;
        }

        .action-item img {
            height: 40px;
            padding: 5px;
            border: 1px solid #ccc;
            border-radius: 50%;
        }

        .action-item {
            opacity: 1;
            border: none !important;
            padding: 0 !important;
            margin-left: .75rem;
        }

        .record-right {
            min-width: 50px;
            margin-bottom: 0;
            padding: 0;
            bordeR: none;
            border-radius: 0;
        }

        .record-rights {
            flex-direction: unset;
        }

        .record-rights .record-right:nth-child(2) {
            border-left: 1px solid #ddd;
            margin-left: .5rem;
        }

        .action-item.trash img{
            height: 30px;
            border:none;
            border-radius: 0;
        }
    }

</style>
