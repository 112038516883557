<template>
    <div class="text-center datacard">
        <p class="data">{{data}}</p>
        <p class="value">{{show_data}}</p>
    </div>
</template>

<script>
    export default {
        name: "DataCard",
        props: ['data', 'value', 'type'],
        computed:{
            show_data: function () {
                if(this.type==='percentage') return (this.value).toFixed(2) + '%';
                if(this.type==='time') return parseInt(this.value/60%60) + ':' + parseInt(this.value%60);
                else return this.value
            }
        }
    }
</script>

<style scoped>
    .datacard {
        height: 100px;
        width: 250px;
        background-color: #f3f4f6;
        margin: 10px;
        border-radius: 10px;
    }

    @media(max-width: 1024px){
      .datacard{
        width: 100%;
        margin: 0;
      }
    }

    .data {
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .value {
        font-size: 2.5rem;
        color: #487E9C;
    }
</style>
