<template>
    <div class="box-left double-spaced">
        <div class="btn btn-outline box-left spaced" @click="$emit('addToList')">
            <img height="15" src="@/assets/icons/agregar-boton.svg">
            <span>AGREGAR A UNA LISTA</span>
        </div>
        <div v-if="!admin" class="btn btn-outline box-left spaced" @click="$emit('quote')">
            <img height="15" src="@/assets/icons/agregar-boton.svg">
            <span>SOLICITAR COTIZACIÓN</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ActionRecords",
        props: ['admin']
    }
</script>

<style scoped>
    @media (max-width: 950px) {
        .box-left>div:first-child{
            margin-right:.75rem;
        }

        .box-left{
            width: 100%;
            padding:.75rem;
            box-shadow: 0 -3px 6px rgba(0,0,0,0.1);
        }

        .btn{
            font-size: .8rem;
            padding:.75rem .5rem;
            text-align: center;
            justify-content: center !important;
            background-color: #3c627a;
            color:#fff;
            border:none;
            font-weight: 500;
        }

        .btn img{
            display: none;
        }
    }

</style>
