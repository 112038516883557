<template>
    <section>
        <div class="container-fluid">
            <div class="w700">
                <div class="gTableHeader">
                    <div>Nombre</div>
                    <div>Acciones</div>
                </div>
                <div class="gRecordItem" v-for="item in rtCategories">
                    <div class="text-left">
                        <span>{{ item.name }}</span>
                    </div>
                    <div>
                        <div class="box-center actions double-spaced">
                            <router-link :to="`/admin/categorias/${item.id}`">
                                <img height="25" src="@/assets/icons/autoral.svg">
                            </router-link>
                            <div v-if="!item.is_genre && !item.is_catalog">
                                <a @click="selectedListId = item.id; dialog.value = true"><img height="25"
                                                                                               src="@/assets/icons/borrar.svg"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <yes-no-dialog :dialog="dialog"
                           title="¿Eliminar categoría?" text="¿Esta seguro de que quiere eliminar esta categoría?"
                           v-on:no="dialog.value = false"
                           v-on:yes="deleteCategory"
            ></yes-no-dialog>
        </div>
    </section>

</template>

<script>
    import YesNoDialog from "../../../components/dialogs/YesNoDialog";

    export default {
        name: "Categorias",
        components: {YesNoDialog},
        props: {
            propCategories: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            selectedListId: -1,
            dialog: {value: false},
            newCategory: '',
            creating: false,
            rules: {
                required: value => !!value || 'Requerido.'
            },
            rtCategories: []
        }),
        mounted() {
        },
        methods: {
            deleteCategory: function () {
                var id = this.selectedListId;
                var self = this;
                this.$axios.post('admin-app/categorias/eliminar', {id: id})
                    .then(function (response) {
                        self.rtCategories = self.rtCategories.filter(function (obj) {
                            return obj.id !== id;
                        });
                        self.dialog.value = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            }
        },
        watch: {
            'propCategories': function (newVal) {
                this.rtCategories = this.propCategories;
            }
        }
    }
</script>

<style scoped>
    .text-left {
        padding-left: 1.5rem;
    }

    .gRecordItem, .gTableHeader {
        grid-template-columns: 70% 30%;
    }

</style>
