<template>
    <main>
        <nav-analytics :active-tab="activeTab"></nav-analytics>
        <section class="analytics-wrap">
            <div class="container-fluid">
                <analytics-filter-widget :filterOptions="filterOptions"
                                         :filterValues="filterValues"></analytics-filter-widget>
                <analytics-chart ref="analyticsChart" :chart-data="response.data.graph" :filter-on="filterValues.months"
                                 v-if="activeTab.value===1 && response!==undefined"></analytics-chart>
                <analytics-data v-if="activeTab.value===1" :datacards="dataCards"></analytics-data>
                <p style="color: #24404F; margin-left: 10px;" v-if="activeTab.value===1 && activeHeaders !== undefined">
                    Tráfico por países</p>
                <analytics-table v-if="activeHeaders !== undefined" :headers="activeHeaders"
                                 :records="activeRecords"></analytics-table>
            </div>
        </section>
    </main>
</template>

<script>
    import AnalyticsFilterWidget from "../../components/widgets/AnalyticsFilterWidget";
    import AnalyticsTable from "../../components/tables/AnalyticsTable";
    import AnalyticsChart from "./partials/analytics/AnalyticsChart";
    import AnalyticsData from "./partials/analytics/AnalyticsData";
    import NavAnalytics from "../../components/NavAnalytics";

    export default {
        name: "AdminAnalytics",
        components: {NavAnalytics, AnalyticsData, AnalyticsChart, AnalyticsTable, AnalyticsFilterWidget},
        data: () => ({
            activeTab: {value: 1},
            tabs: {
                1: 'countries',
                2: 'searches',
                3: 'genres',
                4: 'artists',
                5: 'catalog',
                6: 'played',
                7: 'labels',
                8: 'clients'
            },
            filterOptions: {
                years: [2020, 2019],
                months: [{
                    text: 'Enero', value: 1
                }, {
                    text: 'Febrero', value: 2
                }, {
                    text: 'Marzo', value: 3
                }, {
                    text: 'Abril', value: 4
                }, {
                    text: 'Mayo', value: 5
                }, {
                    text: 'Junio', value: 6
                }, {
                    text: 'Julio', value: 7
                }, {
                    text: 'Agosto', value: 8
                }, {
                    text: 'Septiembre', value: 9
                }, {
                    text: 'Octubre', value: 10
                }, {
                    text: 'Noviembre', value: 11
                }, {
                    text: 'Diciembre', value: 12
                }]
            },
            filterValues: {
                months: 0,
                year: new Date().getFullYear(),
            },
            response: undefined,
        }),
        mounted() {
            this.getAnalytics()
        },
        methods: {
            getAnalytics: function () {
                var self = this;
                this.$axios.get('admin-app/get-analytics', {params:{anio: this.filterValues.year}})
                    .then(function (response) {
                        self.response = response.data;
                        self.filterOptions.years = response.data['años'];
                        if(self.$refs.analyticsChart !== undefined) {
                            self.$refs.analyticsChart.renderData()
                        }
                    })
                    .catch(function (error) {
                        self.response = undefined
                        self.$utils.validate(error.response, self)
                    })
            }
        },
        computed: {
            activeHeaders: function () {
                if (this.response !== undefined) {
                    return this.response.headers[this.tabs[this.activeTab.value]]
                } else {
                    return undefined
                }
            },
            activeRecords: function () {
                if (this.response !== undefined) {
                    var self = this
                    var records_raw = this.response.data[this.tabs[this.activeTab.value]];
                    if (self.filterValues.months === undefined) {
                        self.filterValues.months = 0
                    }
                    var records_filtered = records_raw.filter(function (item) {
                        return parseInt(item['ga:month']) === self.filterValues.months
                    })
                    return records_filtered.sort((a, b) => (a.value > b.value) ? -1 : ((b.value > a.value) ? 1 : 0));
                } else {
                    return {}
                }
            },
            filterOn: function () {
                return this.filterValues.months !== 0
            },
            dataCards: function () {
                var self = this;
                if (this.response !== undefined) {
                    if (this.filterOn) {
                        return this.response.data.traffic[1].filter(function (item) {
                            return parseInt(item['ga:month']) === self.filterValues.months
                        })[0]
                    } else {
                        return this.response.data.traffic[0][0]
                    }
                } else {
                    return undefined
                }
            }
        },
        watch: {
            'filterValues.year': function () {
                this.getAnalytics()
            }
        }
    }
</script>

<style scoped>
    .analytics-wrap {
        padding-bottom: 4rem;
    }

</style>
