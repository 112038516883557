<template>
    <div>
        <aside class="action-overlay" :class="{active: active.value}">
            <div class="body pad scroll-y">
                <pre>{{lyrics}}</pre>
            </div>
        </aside>
        <div class="back-overlay" :class="{active: active.value}" @click="active.value=false"></div>
    </div>
</template>

<script>
    export default {
        name: "LyricsAsideDialog",
        props: ['active', 'lyrics']
    }
</script>

<style scoped>

    .action-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 350px;
        background-color: #fafafa;
        z-index: 99;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        transform: translateX(100%);
        transition: 0.2s all ease-in-out;
    }

    .action-overlay.active {
        transform: translateX(0%);
    }

    .back-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        z-index: 98;
    }

    .back-overlay.active {
        bottom: 0;
        opacity: 1;
    }

    @media(max-width: 600px){
        .action-overlay{
            width: 290px;
        }
    }

    .scroll-y{
        overflow-y: scroll;
        max-height: 100%;
    }

    pre{
        font-size: .9rem;
    }

</style>
