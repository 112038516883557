<template>
    <v-dialog v-model="dialog.value" persistent max-width="400px">
        <v-card>
            <v-card-title>
                <span class="headline">¿Olvidó su contraseña?</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Correo" required
                                              v-model="dialog.email"
                                              placeholder="Ingrese su correo."
                                              :rules="rules.emailMatch"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog.value = false">Cancelar</v-btn>
                <v-btn color="blue darken-1" :disabled="!valid" text @click="recover()">Enviar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "PasswordRecovery",
        props: ['dialog'],
        data: () => ({
            rules: {
                required: value => !!value || 'Requerido.',
                emailMatch: [
                    v => !!v || 'El correo es requerido',
                    v => /.+@.+\..+/.test(v) || 'Debe ser un correo valido',
                ],
            },
            valid: false
        }),
        methods: {
            recover: function () {
                this.$emit('recover');
            }
        }
    }
</script>

<style scoped>

</style>
