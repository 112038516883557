<template>
    <section class="pad10">
        <div class="container-fluid">
            <div class="gSearch">
                <div>
                    <div class="search-wrap">
                        <input class="naui-input search-input" placeholder="Buscar" v-model="search.value"
                               v-on:keyup="debouceFunc">
                        <img height="18" class="lupa" src="@/assets/icons/lupa.svg" v-if="search.value.length === 0" @click="$emit('search')">
                        <img height="18" class="lupa" src="@/assets/icons/vaciar-busqueda.svg" v-else @click="search.value = ''; $emit('search')">
                    </div>
                </div>
                <div>
                    <div class="note back-gris rounded">{{ total }} Registros</div>
                </div>
                <div v-if="actions" class="actions box-right double-spaced">
                    <router-link :to="{name: 'AdminUpload'}" class="btn btn-rojo box-center spaced" style="color: white">
                        <i class="fa fa-plus"></i>
                        <span>CARGAR CANCIÓN</span>
                    </router-link>
                    <input type="file" style="display: none" id="input-file" ref="file"
                           @change="uploadExcel">
                    <div class="upload-area">
                    <label for="input-file">
                        <div class="btn btn-outline box-center spaced">
                            <i class="fa fa-upload"></i>
                            <span>CARGA MASIVA</span>
                        </div>
                    </label>
                        <a @click="descargarPlantilla()" class="download-csv-model">Descargar plantilla CSV</a>
                    </div>
                </div>
                <div v-if="userActions" class="actions box-right double-spaced">
                    <div class="btn btn-rojo box-center spaced" @click="$emit('newUserButton')">
                        <i class="fa fa-plus"></i>
                        <span>NUEVO USUARIO</span>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar v-model="uploadSnackbar" :timeout="4000">
            El excel se subió y esta siendo procesado
            <v-btn color="blue" text @click="createdSnackbar = false">Cerrar</v-btn>
        </v-snackbar>
        <loading-dialog :dialog="downloadDialog" text="Descargando"></loading-dialog>
    </section>
</template>

<script>
    import LoadingDialog from "../../../components/dialogs/LoadingDialog";
    const FileDownload = require('js-file-download');

    export default {
        name: "AdminSearch",
        components: {LoadingDialog},
        props: ['actions', 'userActions', 'total', 'search', 'total'],
        data: () => ({
            uploadSnackbar: false,
            downloadDialog: false
        }),
        methods: {
            debouceFunc: function () {
                // clears the timer on a call so there is always x seconds in between calls
                clearTimeout(this.timer);

                // if the timer resets before it hits 150ms it will not run
                this.timer = setTimeout(function () {
                    this.$emit('search')
                }.bind(this), 300);
            },
            descargarPlantilla: function ()  {
                this.downloadDialog = true;
                var self = this;
                this.$axios.get(`/admin-app/get-template`)
                    .then(function (response) {
                        FileDownload(response.data, 'Plantilla.csv');
                        self.downloadDialog = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self);
                        self.downloadDialog = false;
                    })
            },
            uploadExcel: function () {
                var file = this.$refs.file.files[0];
                if (file === undefined) {
                    return false
                }
                if (file.name.split(".").pop() !== 'csv' && file.name.split(".").pop() !== 'xls' && file.name.split(".").pop() !== 'xlsx') {//check if file extension is csv
                    return false;
                }

                var self = this;
                var formData = new FormData();
                formData.append("file", file);
                this.$axios.post(`admin-app/subir-excel`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.uploadSnackbar = true;
                    })
                    .catch(function (error) {

                    })
            }
        }
    }
</script>

<style scoped>
    .search-wrap {
        position: relative;
    }

    .search-wrap .lupa {
        position: absolute;
        right: 1rem;
        top: .65rem;
        color: #487E9C;
    }

    .gSearch {
        display: grid;
        grid-template-columns: 500px 200px 1fr;
        align-items: center;
        grid-gap: 2rem;
    }

    @media (max-width: 1024px) {
      .gSearch{
        display: block;
        padding:0 1rem;
      }
      .gSearch input{
        margin-bottom:1rem;
      }
    }


    .note {
        padding: .5rem 1rem;
        color: #333333;
        font-size: .95rem;
        font-weight: 500;
        border-radius: 5px;
    }

    .actions .btn {
        width: 190px;
    }

    .upload-area{
        position: relative;
    }

    .download-csv-model{
        position: absolute;
        bottom:-1.75rem;
        font-size: .9rem;
        text-decoration: underline;
        width: 100%;
        text-align: center;
    }

    @media(max-width: 1024px){
      .actions{
        padding-top:1rem;
        padding-bottom:1rem;
      }
    }

</style>
