<template>
    <header class="naui-shadow" :class="{scroll: classScroll }">
        <div class="container-fluid">
            <div class="gHeader">
                <div class="spacer"></div>
                <div class="box-center">
                    <img class="logo" height="50" src="@/assets/pix/landing/logo.png">
                    <div class="box-center double-spaced buttons">
                        <div @click="$emit('contact')" class="btn btn-rojo">CONTÁCTENOS</div>
                    </div>
                </div>
                <Rrss :rrss="rrss"></Rrss>
            </div>
        </div>
    </header>
</template>

<script>
    import Rrss from "./Rrss";

    export default {
        name: "SplashHeader",
        components: {Rrss},
        props: {
          scroll: {
            type: Boolean,
            default: false
          },
          rrss: {
            type: Array,
            default: () => []
          }
        },
        computed: {
            classScroll: function(){
                return this.top < 80 && this.top !== null;
            }
        }
    }
</script>

<style scoped>
    header {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #fff;
    }

    .gHeader {
        padding: 1rem;
        display: grid;
        grid-template-columns: 200px 1fr auto;
    }

    .buttons .btn {
        width: 200px;
        text-align: center;
        border-radius: 3rem;
        font-weight: 600;
        height: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
    }

    .buttons .btn-transparent {
        background-color: rgba(255, 255, 255, 0.4);
        color: #fff;
    }

    .btn-rojo {
        background: linear-gradient(to right, #F11B46, #BD1231);
    }

    .btn-rojo:active {
        background: #BD1231;
    }

    .btn.back-gris {
        color: #333;
    }

    .buttons {
        display: none;
    }

    .scroll .buttons{
        display: flex;
        margin-left: 3rem;
    }

    .scroll .spacer{
        display: none;
    }

    .scroll .gHeader{
        grid-template-columns: 1fr 200px;
    }

    .scroll .gHeader>.box-center{
        justify-content: flex-start;
    }

    @media(max-width: 950px){
        .scroll .buttons{
            display: none;
        }

        .spacer{
            display: none;
        }

        header{
            position: relative;
        }

        .gHeader{
            display: block;
        }

        .logo{
            margin-bottom: 1rem;
        }
    }

</style>
