<template>
    <section class="pad10">
        <div class="w900 form-content">
            <div class="grid2 rgrid gap10">
                <div>
                    <v-text-field
                            label="Nombre"
                            v-model="perfil.name"
                            :readonly="!editing"></v-text-field>
                </div>
                <div>
                    <v-text-field
                            label="Correo"
                            v-model="perfil.email"
                            :readonly="!editing"></v-text-field>
                </div>
            </div>
            <div class="grid2 rgrid gap10">
                <div class="box-left spaced">
                    <div style="max-width: 120px;">
                        <v-select
                                :items="items"
                                v-model="perfil.doc_type"
                                :readonly="!editing"></v-select>
                    </div>
                    <v-text-field
                            label="Documento"
                            v-model="perfil.doc_num"
                            :readonly="!editing"></v-text-field>
                </div>
                <div>
                    <v-text-field
                            label="Empresa"
                            v-model="perfil.workplace"
                            :readonly="!editing"></v-text-field>
                </div>
            </div>
            <div class="grid2 rgrid gap10">
                <div>
                    <v-text-field
                            label="Teléfono de contacto"
                            v-model="perfil.phone"
                            :readonly="!editing"></v-text-field>
                </div>
                <div>

                </div>
            </div>
        </div>
        <div class="actions" v-if="editable">
            <div class="box-left">
                <div class="btn btn-outline box-center spaced" v-if="!editing" @click="editing=true">
                    <i class="fa fa-edit"></i>
                    <span>EDITAR DATOS</span>
                </div>
                <div class="btn btn-rojo box-center spaced" v-if="editing" @click="saveEdit">
                    <i class="fa fa-save"></i>
                    <span>GUARDAR</span>
                </div>
            </div>
        </div>
        <div class="actions" v-if="$route.path.startsWith('/admin') && adminEdit">
            <div class="box-left">
                <div class="btn btn-rojo box-center spaced" v-if="editing" @click="adminSaveEdit">
                    <i class="fa fa-save"></i>
                    <span>GUARDAR</span>
                </div>
            </div>
        </div>
        <v-snackbar v-model="snackbar" :timeout="4000">
            Perfil guardado con éxito
            <v-btn color="blue" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
    </section>
</template>

<script>
    import LoadingDialog from "../../../components/dialogs/LoadingDialog";

    export default {
        name: "DatosPersonales",
        components: {LoadingDialog},
        props: ['perfil', 'items', 'editable', 'adminEdit'],
        data: () => ({
            editing: false,
            snackbar: false
        }),
        mounted() {
            if (this.adminEdit) {
                this.editing = true
            }
        },
        methods: {
            saveEdit: function () {
                const self = this;
                this.$axios.post('editar-perfil', {
                    user: this.perfil
                })
                    .then(function () {
                        self.snackbar = true;
                        self.editing = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            },
            adminSaveEdit: function () {
                const self = this;
                this.$axios.post(`admin-app/usuarios/${this.$route.params.id}/editar-perfil`, {
                    user: this.perfil
                })
                    .then(function () {
                        self.snackbar = true;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            }
        }
    }
</script>

<style scoped>
    .btn {
        min-width: 200px;
    }

    @media (max-width: 950px) {
        .form-content {
            margin-bottom: 150px;
        }

        .actions {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: #fff;
            padding: .75rem;
            box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);
        }

        .actions .btn {
            width: 100%;
        }
    }

</style>
