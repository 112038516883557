<template>
    <main>
        <SimpleHeader :title="listMeta.name" :note="listMeta.count + ' Canciones'" :link="false"></SimpleHeader>
        <admin-record-table :checked-songs="checkedSongs" :ini-records="records" :pagination="pagination"></admin-record-table>
    </main>

</template>

<script>
    import SimpleHeader from "../../components/SimpleHeader";
    import RecordTable from "../../components/tables/RecordTable";
    import HeaderClient from "../../components/HeaderClient";
    import AdminRecordTable from "../../components/tables/AdminRecordTable";

    export default {
        name: "AdminListaInterna",
        components: {AdminRecordTable, HeaderClient, RecordTable, SimpleHeader},
        data: () => ({
            records: [],
            checkedSongs: [],
            pagination: {page: 1, pages: 1},
            listMeta: {name: 'Lista', count: 0, id: -1}
        }),
        mounted: function () {
            var self = this;
            this.$axios.get(`playlists/${this.$route.params.id}`, {
                params: {
                    p: self.pagination.page
                }
            })
                .then(function (response) {
                    self.records = response.data.songs;
                    self.listMeta = response.data.list_meta;
                    self.pagination = response.data.pagination;
                })
                .catch(function (error) {
                    self.$utils.validate(error.response, self)
                })
        },
        methods: {
            renameList: function () {
                if (this.$refs.form.validate()) {
                    var self = this;
                    this.$axios.post(`playlists/${this.$route.params.id}/renombrar`, {
                        'new_name': self.newName
                    })
                        .then(function (response) {
                            self.listMeta.name = response.data.new_name;
                            self.renaming = false;
                        })
                        .catch(function (error) {
                            self.$utils.validate(error.response, self)
                        })
                }
            }
        }
    }
</script>

<style scoped>
    .buttons .btn {
        min-width: 200px;
    }

    a.btn-rojo{
        color:#fff;
    }

</style>
