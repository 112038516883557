<template>
    <main>
        <SimpleHeader :title="listMeta.name" :note="listMeta.count + ' Canciones'" back="/listas"></SimpleHeader>
        <section>
            <div class="container-fluid pad">
                <div class="box-left double-spaced buttons acciones">
                    <router-link :to="{path:'/solicitar-cotizacion', query:{playlist: listMeta.id}}"
                                 class="btn btn-rojo box-center spaced">
                        <i class="fa fa-cart-plus"></i>
                        <span>COTIZAR ESTA LISTA</span>
                    </router-link>
                    <div class="btn btn-outline box-center" v-if="!renaming" @click="renaming = true">
                        <span>RENOMBRAR LISTA</span>
                    </div>
                    <v-form ref="form" v-if="renaming" class="box-left double-spaced">
                        <div class="btn btn-outline box-center" @click="renameList">
                            <span>GUARDAR</span>
                        </div>
                        <v-text-field
                                :rules="[rules.required]"
                                label="Nuevo Nombre"
                                v-model="newName"
                        ></v-text-field>
                        <div class="btn btn-outline box-center" @click="renaming = false">
                            <span>CANCELAR</span>
                        </div>
                    </v-form>
                </div>
            </div>
        </section>
        <RecordTable :checked-songs="checkedSongs" :records="records" :pagination="pagination"></RecordTable>
    </main>

</template>

<script>
    import SimpleHeader from "../../components/SimpleHeader";
    import RecordTable from "../../components/tables/RecordTable";
    import HeaderClient from "../../components/HeaderClient";

    export default {
        name: "ClientListaInterna",
        components: {HeaderClient, RecordTable, SimpleHeader},
        data: () => ({
            records: [],
            checkedSongs: [],
            pagination: {page: 1, pages: 1},
            listMeta: {name: 'Lista', count: 0, id: -1},
            newName: '',
            renaming: false,
            rules: {
                required: value => !!value || 'Requerido.'
            }
        }),
        mounted: function () {
            var self = this;
            this.$axios.get(`playlists/${this.$route.params.id}`, {
                params: {
                    p: self.pagination.page
                }
            })
                .then(function (response) {
                    self.records = response.data.songs;
                    self.listMeta = response.data.list_meta;
                    self.pagination = response.data.pagination;
                })
                .catch(function (error) {
                    self.$utils.validate(error.response, self)
                })
        },
        methods: {
            renameList: function () {
                if (this.$refs.form.validate()) {
                    var self = this;
                    this.$axios.post(`playlists/${this.$route.params.id}/renombrar`, {
                        'new_name': self.newName
                    })
                        .then(function (response) {
                            self.listMeta.name = response.data.new_name;
                            self.renaming = false;
                        })
                        .catch(function (error) {
                            self.$utils.validate(error.response, self)
                        })
                }
            }
        }
    }
</script>

<style scoped>
    .buttons .btn {
        min-width: 200px;
    }

    a.btn-rojo{
        color:#fff;
    }

    @media(max-width: 600px){
        .acciones{
            display: block;
            padding:1rem 0;
        }

        .acciones>*{
            margin: 0;
            display: block;
            text-align: center;
        }

        .acciones>*:last-child{
            margin-top:1rem;
        }
    }
</style>
