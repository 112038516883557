<template>
  <div class="box-left double-spaced filter-widget">
    <span class="desktop"><i class="fa fa-filter"></i></span>
    <div class="box-left spaced">
      <v-select v-if="filterOptions.perfil"
                v-on:change="$emit('filterChange')"
                v-model="filterValues.perfil"
                :items="filterOptions.perfil"
                label="Perfil"
                :clearable="true"
      ></v-select>
      <v-autocomplete v-if="filterOptions.generos"
                      v-on:change="$emit('filterChange')"
                      v-model="filterValues.genero"
                      :items="filterOptions.generos"

                      label="Género"
                      :clearable="true"
      ></v-autocomplete>
      <v-autocomplete v-if="filterOptions.autores"
                      v-on:change="$emit('filterChange')"
                      v-model="filterValues.autor"
                      :items="filterOptions.autores"
                      :filter="filterArtistas"
                      label="Artista"
                      :clearable="true"
      ></v-autocomplete>
      <v-autocomplete v-if="filterOptions.catalogos"
                      v-on:change="$emit('filterChange')"
                      v-model="filterValues.catalogo"
                      :items="filterOptions.catalogos"
                      label="Cátalogos"
                      :clearable="true"
      ></v-autocomplete>
      <v-select v-if="filterOptions.derechos"
                v-on:change="$emit('filterChange')"
                v-model="filterValues.derechos"
                :items="filterOptions.derechos"
                label="Derechos"
                :clearable="true"
      ></v-select>
    </div>
  </div>

</template>

<script>
var accents = require('remove-accents');

export default {
  name: 'FilterWidget',
  props: ['filterOptions', 'filterValues'],
  methods: {
    filterArtistas: (item, queryText, itemText) => {
      return accents.remove(itemText).toLocaleLowerCase().indexOf(accents.remove(queryText).toLocaleLowerCase()) > -1
    }
  }
}
</script>

<style scoped>
span {
  color: #828282;
  width: max-content;
}

.naui-select {
  color: #494949;
  min-width: 200px;
}

.filter-widget {
  flex: auto;
  padding-top: .5rem;
}

@media (max-width: 1024px) {
  .desktop {
    display: none;
  }
}


</style>
