<template>
  <section class="back-gris pad20">
    <div class="container-fluid">
      <div class="grid3 gap1">
        <div v-for="item in categories" class="rounded box-center w-100">
          <div class="naui-overlay-container w-100">
            <router-link :to="{name:'ClientSubCategory', params:{slug:item.slug}}">
            <img class="category-image w-100 rounded" :src="item.img">
            <div class="naui-overlay">
              <div class="w-100 h-100 box-center">
                <h3>{{ item.name }}</h3>
              </div>
            </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
  export default {
    name: "FeaturedCategories",
    props: ['categories'],
    data() {
      return {}
    }
  }
</script>

<style scoped>
  a {
    cursor: pointer;
  }

  section.pad20 {
    padding-top: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    text-align: center;
    padding: 0 1.5rem;
    color: #fff;
  }

    @media (max-width: 1000px) {
        .grid3{
            grid-template-columns: 1fr;
            padding: 0 1rem;
        }
    }


  .category-image{
    aspect-ratio: 389 / 138;
  }

</style>
