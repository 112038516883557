<template>
    <section>
        <div class="container-fluid">
            <div class="gTableHeader">
                <div class="text-center">
                    <v-checkbox v-if="checkboxes" @change="selectAll" v-model="allSelected"></v-checkbox>
                    Canción
                </div>
                <div class="text-center">Artista</div>
                <div class="text-center">Género</div>
                <div class="text-center">Duración</div>
                <div class="text-center">Derecho</div>
                <div class="text-center">Letra</div>
                <div class="text-center">Acciones</div>
            </div>
            <template v-for="item in records">
                <div class="gRecordItem">
                    <div class="box-left double-spaced main-item">
                        <v-checkbox v-if="checkboxes" :value-comparator="valueComparator" :value="JSON.stringify(item)"
                                    v-model="checkedSongs.value"></v-checkbox>
                        <div class="cover naui-overlay-container">
                            <img class="image" style="max-height: 60px;" :src="item.cover">
                            <div class="naui-overlay">
                                <div class="box-center h-100 w-100">
                                    <img height="25" src="@/assets/icons/play-lista.svg" @click="play(item)"
                                         v-if="showPlay(item)">
                                    <img height="25" src="@/assets/icons/pausa.svg" @click="pause()"
                                         v-if="showPause(item)">
                                </div>
                            </div>
                        </div>
                        <div>
                            <router-link :to="`/cancion/${item.id}`"><span>{{ item.title }}</span></router-link>
                            <div class="mobile artist">{{ item.artist }}</div>
                            <div class="mobile  genre">{{ item.genre }}</div>
                            <div class="mobile field record-rights">
                                <div v-if="item.fono" class="box-center record-right">
                                    <img height="30" src="@/assets/icons/autoral.svg">
                                    <span>Autoral {{ item.autoral }}</span>
                                </div>
                                <div class="box-center record-right">
                                    <img height="30" src="@/assets/icons/fono.svg">
                                    <span>Fono</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center desktop ">
                        <span class="field artist">{{ item.artist }}</span>
                    </div>
                    <div class="text-center desktop">
                        <span class="field  genre">{{ item.genre }}</span>
                    </div>
                    <div class="text-center">
                        <span v-if="item.dur" class="field duration">{{ item.dur }}</span>
                    </div>
                    <div class="box-center grid-rights desktop">
                        <div class="field record-rights">
                            <div v-if="item.fono" class="box-center record-right">
                                <img height="30" src="@/assets/icons/autoral.svg">
                                <span>Autoral {{ item.autoral }}</span>
                            </div>
                            <div class="box-center record-right">
                                <img height="30" src="@/assets/icons/fono.svg">
                                <span>Fono</span>
                            </div>
                        </div>
                    </div>
                    <div class="box-center action-item">
                        <img v-if="item.lyrics !== ''" height="30" style="cursor: pointer"
                             src="@/assets/icons/letras.svg"
                             @click="active.value=true; activeLyrics=item.lyrics">
                    </div>
                    <div class="box-center actions">
                        <div class="action-item">
                            <img height="25" src="@/assets/icons/estrella.svg"
                                 @click="$emit('addToList', JSON.stringify(item))"
                                 style="cursor: pointer">
                        </div>
                        <div class="action-item">
                            <img height="25" src="@/assets/icons/carrito.svg"
                                 @click="addSong(item)"
                                 style="cursor: pointer">
                        </div>
                        <div v-if="$route.path.startsWith('/lista')" @click="removeSongs(item.id)">
                            <img height="25" src="@/assets/icons/borrar.svg" style="cursor: pointer">
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <lyrics-aside-dialog :lyrics="activeLyrics" :active="active"></lyrics-aside-dialog>
        <v-snackbar v-model="snackbar" :timeout="4000">
            Canción agregada a cotización
            <v-btn color="blue" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
        <div class="pagination-wrap box-left" v-if="pagination !== undefined && pagination.pages > 1">
            <span class="current-page">Pág. {{ pagination.page }} / {{ pagination.pages }}</span>
            <v-pagination
                    v-on:next="$emit('change')"
                    v-on:previous="$emit('change')"
                    v-on:input="$emit('change')"
                    v-model="pagination.page"
                    :length="pagination.pages"
                    total-visible="6"
            ></v-pagination>
        </div>
        <div class="pagination-gap" v-if="pagination !== undefined && pagination.pages > 1"></div>
    </section>
</template>

<script>
    import LyricsAsideDialog from "../dialogs/LyricsAsideDialog";
    import cart from "../../main";

    export default {
        name: "RecordTable",
        components: {LyricsAsideDialog},
        props: ['records', 'checkedSongs', 'pagination', 'checkboxes'],
        data: () => ({
            activeLyrics: '',
            active: {value: false},
            snackbar: false,
            checked: [],
            allSelected: false,
            cart,
            player: null,
            current: null,
            playing: false
        }),
        beforeDestroy() {
            this.pause();
        },
        methods: {
            valueComparator(a, b) {
                const aObject = JSON.parse(a);
                const bObject = JSON.parse(b);
                return aObject.id === bObject.id && this.checked.indexOf(bObject.id) > -1
            },
            removeSongs(id) {
                const self = this;
                this.$axios.post(`playlists/${this.$route.params.id}/quitar-de-playlist`, {ids: [id]})
                    .then(function (response) {
                        self.records = self.records.filter(function (obj) {
                            return obj.id !== id;
                        });
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    });
            },
            selectAll: function () {
                const self = this;
                var recordIds = [];
                this.records.forEach(function (song) {
                    recordIds.push(song.id)
                });
                this.checkedSongs.value = this.checkedSongs.value.filter(function (song) {
                    const jsong = JSON.parse(song);
                    return !self.checked.includes(jsong.id) || !recordIds.includes(jsong.id)
                });
                if (this.allSelected) {
                    var objectSelection = [];
                    this.records.forEach(function (song) {
                        objectSelection.push(JSON.stringify(song));
                    });
                    this.checkedSongs.value.push(...objectSelection);
                }
            },
            addSong: function (song) {
                this.cart.commit('add', song);
                this.snackbar = true;
            },
            play: function (song) {
                if (this.player) {
                    this.player.pause();
                }
                if (this.current && this.player && this.current.id === song.id) {
                    this.player.play();
                } else {
                    this.current = song;
                    this.player = new Audio(song.song);
                    this.player.play();
                }
                this.playing = true;

            },
            pause: function () {
                if (this.player) {
                    this.player.pause();
                    this.playing = false
                }
            },
            showPlay: function (song) {
                if (this.current) {
                    return this.current.id !== song.id || (!this.playing && this.current.id === song.id);
                } else {
                    return true;
                }
            },
            showPause: function (song) {
                if (this.current) {
                    return this.current.id === song.id && this.playing;
                } else {
                    return false;
                }
            }
        },
        watch: {
            'checkedSongs.value': function (newVal) {
                this.checked = [];
                const self = this;
                newVal.forEach(function (item) {
                    self.checked.push(JSON.parse(item).id)
                })
            },
            'records': function (newVal) {
                if (newVal.length > 0) {
                    var selection = [];
                    var selected = true;
                    newVal.forEach(function (song) {
                        selection.push(song.id);
                    });
                    for (let i = 0; i < selection.length; i++) {
                        if (!this.checked.includes(selection[i])) {
                            selected = false;
                            break;
                        }
                    }
                    this.allSelected = selected
                }
            }
        }
    }
</script>

<style scoped>
    .gTableHeader {
        grid-template-columns: 30% 15% 15% 10% 15% 5% 10%;
    }

    .gRecordItem {
        grid-template-columns: 30% 15% 15% 10% 15% 5% 10%;
        display: grid;
        width: 100%;
    }

    thead tr {
        background: linear-gradient(to right, #24404F 0%, #487E9C 100%);
    }

    .cover .naui-overlay {
        opacity: 0;
        transition: .2s;
        cursor: pointer;
    }

    .cover > img {
        border-radius: 4px;
    }

    .cover:hover .naui-overlay {
        opacity: 1;
    }

    .actions > div:first-child {
        border-right: 1px solid #eaeaea;
    }

    .actions > div {
        padding: 0 .5rem
    }

    @media (min-width: 950px) {
        .mobile {
            display: none;
        }
    }

    @media (max-width: 950px) {
        .desktop {
            display: none;
        }

        .gRecordItem {
            grid-template-columns: 120px 1fr;
            align-items: normal;
            grid-gap: .5rem;
            position: relative;
            border-bottom:2px solid #eaeaea;
        }

        .gRecordItem > div:first-child, .gRecordItem > div:last-child {
            grid-column: 1/3;
        }

        .gRecordItem > div {
            padding: 0;
        }

        .gTableHeader {
            display: none;
        }

        .double-spaced>*{
            margin:0;
        }

        .image{
            margin-right:1rem;
            max-height: 80px !important;
        }

        .actions{
            justify-content: flex-end;
        }

        .duration{
            justify-content: flex-start;
        }

        .grid-rights{
            justify-content: flex-start;
        }

        .gRecordItem .duration{
            position: absolute;
            top:100px;
            left:40px;
            font-size: .8rem;
        }

        .main-item{
            align-items: flex-start;
            font-size: .9rem;
        }

        .main-item>*{
            align-self: flex-start;
        }

        .action-item img{
            height: 40px;
            padding:5px;
            border:1px solid #ccc;
            border-radius:50%;
        }

        .action-item{
            opacity: 1;
            border:none !important;
            padding:0 !important;
            margin-left:.75rem;
        }

        .record-right{
            min-width: 50px;
            margin-bottom: 0;
            padding:0;
            bordeR:none;
            border-radius:0;
        }

        .record-rights{
            flex-direction: unset;
        }

        .record-rights .record-right:nth-child(2){
            border-left:1px solid #ddd;
            margin-left:.5rem;
        }
    }

</style>
