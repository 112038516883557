<template>
    <main>
        <simple-header :title="'Categorías'" :link="false"></simple-header>
        <section @click="dialogActive.value = true" class="form-group box-left boton">
            <div class="container-fluid">
                <div class="btn-form btn btn-rojo box-center spaced"><i class="fa fa-plus"></i><span>CREAR NUEVA CATEGORÍA</span></div>
            </div>
        </section>
        <categorias :prop-categories="categories"></categorias>
        <form-aside-new v-on:saved="newCat" :active="dialogActive" object="category"></form-aside-new>
    </main>
</template>

<script>
    import AdminHeader from "./partials/AdminHeader";
    import Categorias from "./partials/Categorias";
    import YesNoDialog from "../../components/dialogs/YesNoDialog";
    import FormAsideNew from "../../components/forms/FormAsideNew";
    import SimpleHeader from "../../components/SimpleHeader";

    export default {
        name: "AdminCategory",
        components: {SimpleHeader, FormAsideNew, YesNoDialog, Categorias, AdminHeader},
        data: () => ({
            categories: [],
            dialog: false,
            dialogActive: {value: false},
        }),
        mounted() {
            this.refresh()
        },
        methods: {
            newCat: function (response) {
                this.dialogActive.value = false;
                this.refresh();
            },
            refresh: function () {
                var self = this;
                this.$axios.get('admin-app/categorias')
                    .then(function (response) {
                        self.categories = response.data;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            }
        }
    }
</script>

<style scoped>
    .boton {
        margin-top: 1rem;
    }

</style>
