<template>
  <header class="naui-shadow">
    <div class="gHeader">
      <div>
        <router-link :to="{name: 'AdminMain'}">
          <img height="50" src="@/assets/pix/landing/logo.png">
        </router-link>
      </div>
      <div>
        <nav id="menu" :class="{'active':active}" class="box-center w-100 space-around">
          <router-link :to="{name: 'AdminMain'}"
                       v-if="!cart.state.prohibited !== '*' && !cart.state.prohibited.includes('')"
                       :class="{'active': this.$route.path === '/admin' || this.$route.path.startsWith('/admin/solicitud'), 'nav-link': true}">
            Solicitudes de cotización
          </router-link>
          <router-link  :active-class="'active'" :to="{name: 'AdminCatalogo'}" class="nav-link">
            Admon. Catálogo
          </router-link>
          <router-link :active-class="'active'" :to="{name: 'AdminCategory'}" class="nav-link">
            Admon. Categorías
          </router-link>
          <router-link  v-if="!cart.state.prohibited !== '*' && !cart.state.prohibited.includes('usuarios')"
                       :active-class="'active'" :to="{name: 'AdminUsers'}" class="nav-link">
            Usuarios
          </router-link>
          <router-link   v-if="!cart.state.prohibited !== '*' && !cart.state.prohibited.includes('analytics')"
                       :active-class="'active'" :to="{name: 'AdminAnalytics'}" class="nav-link">
            Web Analytics
          </router-link>
        </nav>
      </div>
      <div class="box-right">
        <Logout></Logout>
        <div class="ham" @click="active=!active">
          <i class="fa fa-bars"></i>
        </div>
      </div>
    </div>
    <div id="menu-overlay" @click="active=!active" :class="{'active':active}"></div>
  </header>

</template>

<script>
import Logout from "../../../components/Logout";
import cart from "../../../main";

export default {
  name: "AdminHeader",
  components: { Logout },
  data: () => ({
    cart,
    active: false
  })
}
</script>

<style scoped>
header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  align-items: center;
}


.ham {
  display: none;
}

.gHeader {
  display: grid;
  grid-template-columns: auto 1fr 200px;
  grid-gap: 3rem;
  align-items: center;
  padding: .75rem 1rem;
}

.space-around {
  justify-content: space-around;
}

.btn-cerrar {
  border-radius: 2rem;
  font-size: .85rem;
  cursor: pointer;
  width: max-content;
}

.nav-link {
  color: #333333;
  border-bottom: 1px solid transparent;
}

.nav-link.active {
  border-bottom: 1px solid #FA1B42;
}

.btn-cerrar {
  border-radius: 2rem;
  font-size: .85rem;
  cursor: pointer;
}

.cerrar-sesion {
  width: 40px;
  height: 40px;
  padding: 0;
  position: relative;
}

.cerrar-sesion img {
  position: relative;
  top: -2px;
}

.cerrar-sesion:after {
  content: "CERRAR SESIÓN";
  position: absolute;
  width: max-content;
  border: 1px solid #eaeaea;
  padding: .1rem .25rem;
  font-size: .8rem;
  border-radius: 3px;
  display: none;
  bottom: -1.6rem;
  left: -60px;
  background-color: #fff;
}

.cerrar-sesion:hover:after {
  display: block;
}


@media (max-width: 1024px) {

  .gHeader{
    grid-gap:0;
  }

  #menu-overlay{
    position: fixed;
    z-index:98;
    top:0;
    left:0;
    right:0;
    opacity: 0;
    background: rgba(0,0,0,0.3);
    transition: 0.2s ease-in-out all;
  }

  #menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    display: block;
    background: white;
    z-index: 99;
    width: 270px;
    padding: 1rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    transition: .2s ease-in-out all;
  }

  #menu.active{
    transform: translateX(0%);
  }

  #menu > * {
    display: block;
    padding: .5rem;
    margin-bottom: 1rem;
  }

  .ham {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: #487E9C;
    margin-left: 1rem;
  }

  #menu-overlay.active{
    position: fixed;
    bottom:0;
    opacity: 1;
  }
}


</style>
