<template>
  <div class="box-left double-spaced filter-widget">
      <span class="desktop"><i class="fa fa-filter"></i> Filtrar por</span>
      <div class="box-left spaced">
          <v-select v-if="filterOptions.years"
                    v-on:change="$emit('filterChange')"
                    v-model="filterValues.year"
                    :items="filterOptions.years"
                    label="Año"
          ></v-select>
            <v-select v-if="filterOptions.months"
                      v-on:change="$emit('filterChange')"
                      v-model="filterValues.months"
                      :items="filterOptions.months"
                      label="Mes"
                      :clearable="true"
            ></v-select>
      </div>
  </div>

</template>

<script>
  export default {
    name: "AnalyticsFilterWidget",
    props: ['filterOptions', 'filterValues']
  }
</script>

<style scoped>
  span {
    color: #828282;
      width: max-content;
  }

  .naui-select {
    color: #494949;
    min-width: 200px;
  }

    .filter-widget{
        flex:auto;
        padding-top:.5rem;
    }

    @media(max-width: 1024px){
      .desktop{
        display: none;
      }
    }
</style>
