<template>
    <div id="app">
        <main>
            <v-app>
                <HeaderClient v-if="showClientHeader && !$route.path.startsWith('/login')"></HeaderClient>
                <AdminHeader v-if="showAdminHeader"></AdminHeader>
                <router-view :key="$route.path.startsWith('/generos') ? undefined : $route.path"></router-view>
            </v-app>
        </main>
    </div>
</template>

<script>
    import HeaderClient from "./components/HeaderClient";
    import AdminHeader from "./views/admin/partials/AdminHeader";
    export default {
        name: 'app',
        components: {AdminHeader, HeaderClient},
        computed: {
            showAdminHeader: function () {
                return this.$route.path.startsWith('/admin') && !this.$route.path.startsWith('/login')
            },
            showClientHeader: function () {
                return !this.showAdminHeader && this.$route.path!=='/' && this.$route.path!=='' && !this.$route.path.startsWith('/gestionar-contrasena')
            }
        }
    }
</script>

<style>

</style>
