<template>
    <section>
        <div class="w700">
            <template v-for="item in listas">
                <div class="gRecordItem">
                    <div class="text-left">
                        <div class="box-left">
                            <router-link class="action-item" :to="`/listas/${item.id}`">
                                <div class="box-left">
                                    <img class="lupa" height="25" src="@/assets/icons/listas-lupa.svg">
                                    <span class="name">{{ item.name }} </span>
                                    </div>
                            </router-link>

                             <span class="count">({{ item.count }})</span>
                        </div>
                    </div>
                    <div class="box-right actions double-spaced">
                        <router-link class="action-item"
                                     :to="{path:'/solicitar-cotizacion', query:{playlist: item.id}}">
                            <img height="25" src="@/assets/icons/carrito.svg">
                        </router-link>

                        <div class="action-item">
                            <a @click="selectedListId = item.id; dialog.value = true"><img height="25"
                                                                                           src="@/assets/icons/borrar.svg"></a>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="box-left pad20">
            <div @click="creating.value = true" class="btn btn-rojo box-left spaced">
                <i class="fa fa-plus"></i>
                <span>CREAR NUEVA LISTA</span>
            </div>
        </div>
        <yes-no-dialog :dialog="dialog"
                       title="¿Eliminar lista?" text="¿Esta seguro de que quiere eliminar esta lista?"
                       v-on:no="dialog.value = false"
                       v-on:yes="deletePlaylist"
        ></yes-no-dialog>
        <form-aside-playlist :active="creating" v-on:refresh="refresh"></form-aside-playlist>
    </section>
</template>

<script>
    import YesNoDialog from "../../../components/dialogs/YesNoDialog";
    import FormAsidePlaylist from "../../../components/forms/FormAsidePlaylist";

    export default {
        name: "MisListas",
        components: {FormAsidePlaylist, YesNoDialog},
        props: {
            listasProp: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            selectedListId: -1,
            dialog: {value: false},
            listas: [],
            newPlaylist: '',
            creating: {value: false},
            rules: {
                required: value => !!value || 'Requerido.'
            }
        }),
        mounted() {
            if (this.listasProp.length === 0) {
                this.refresh();
            } else {
                this.listas = this.listasProp.slice();
            }
        },
        methods: {
            refresh: function () {
                var self = this;
                this.$axios.get('playlists')
                    .then(function (response) {
                        self.listas = response.data;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            },
            deletePlaylist: function () {
                var id = this.selectedListId;
                var self = this;
                this.$axios.post('playlists/eliminar', {id: id})
                    .then(function (response) {
                        self.listas = self.listas.filter(function (obj) {
                            return obj.id !== id;
                        });
                        self.dialog.value = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            }
        }
    }
</script>

<style scoped>
    .gTableHeader, .gRecordItem {
        display: grid;
        grid-template-columns: 70% 30%;
    }

    .lupa{
        display: inline-block;
        margin-right: .25rem;
        position: relative;
        top:2px;
    }

    .action-item{
        opacity: 1;
    }

    .name{
        font-weight: 600;
    }

    .count{
        margin-left:.5rem;
    }

    @media (max-width: 950px) {
        .gTableHeader {
            border-radius: 0;
            padding: 0;
            position: relative;
            top: 0;
        }

        .gTableHeader > div {
            font-size: .9rem;
        }

        .gTableHeader > div:first-child {
            text-align: left;
            justify-content: flex-start;
            padding-left: .75rem;
        }

        .text-left {
            padding-left: 0;
        }

        .acciones{
            display: block;
        }
    }

</style>
