<template>
    <main>
        <SimpleHeader :title="'Solicitar cotización'" :link="false"></SimpleHeader>
        <section>
            <div class="container-fluid">
                <div class="gLayout">
                    <div class="left">
                        <p>Solicita una cotización sobre las obras de tu interés y nuestro equipo comercial se pondrá en
                            contacto</p>
                        <FormQuote ref="formQ" :data="formData" :user="formUser"></FormQuote>
                        <br>
                        <fieldset>
                            <legend>Canciones</legend>
                            <br>
                            <QuoteSongs :records="quoteSongs" v-on:remove="removeSong"></QuoteSongs>
                        </fieldset>
                    </div>
                    <div class="right">
                        <div class="block form-right">
                            <div class="form-group" @click="dialog.value = true">
                                <div class="btn btn-rojo text-center">ENVIAR SOLICITUD</div>
                            </div>
                            <div class="form-group" @click="$router.push('/inicio')">
                                <div class="btn btn-outline text-center">CANCELAR</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <yes-no-dialog :dialog="dialog"
                       title="Enviar solicitud" text="¿Seguro que desea cotizar este listado de canciones?"
                       yes-text="SI, COTIZAR" no-text="AÚN NO"
                       v-on:no="dialog.value = false" v-on:yes="quotate"
        ></yes-no-dialog>
    </main>
</template>

<script>
    import SimpleHeader from "@/components/SimpleHeader";
    import FormQuote from "@/components/forms/FormQuote";
    import QuoteSongs from "../../components/tables/QuoteSongs";
    import YesNoDialog from "../../components/dialogs/YesNoDialog";
    import InformationDialog from "../../components/dialogs/InformationDialog";
    import HeaderClient from "../../components/HeaderClient";
    import cart from "../../main";

    export default {
        name: "ClientQuote",
        components: {HeaderClient, InformationDialog, YesNoDialog, QuoteSongs, FormQuote, SimpleHeader},
        data: () => ({
            formUser: {},
            id_types: [],
            dialog: {value: false},
            quoteSongs: [],
            formData: {
                'medios': {
                    'tv': false,
                    'internet': false,
                    'av': false,
                    'radio': false,
                    'other_media': ''
                },
                'territories': {
                    'world': false,
                    'colombia': false,
                    'other_territories': ''
                },
                'observations': ''
            },
            cart
        }),
        mounted() {
            var playlist = this.$route.query.playlist || -1;
            var self = this;
            this.fillSongs()
            if (playlist === -1) {
                var ids = []
                JSON.parse(localStorage.getItem('quoteSongs')).forEach(function (song) {
                    ids.push(song.id);
                });
                this.$axios.get('check-songs', {params: {ids: ids}}).then(function (response) {
                    localStorage.setItem('quoteSongs', JSON.stringify(response.data.songs))
                    self.cart.state.songs = response.data.songs;
                    self.fillSongs()
                })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    });
            }
            this.$axios.get('cotizar', {params: {playlist: playlist}})
                .then(function (response) {
                    if (playlist !== -1) {
                        self.quoteSongs = response.data.songs;
                        localStorage.setItem('quoteSongs', JSON.stringify(self.quoteSongs))
                    }
                    self.formUser = response.data.user;
                    self.formUser.songplace = self.formUser.workplace;
                    self.id_types = response.data.id_types;
                })
                .catch(function (error) {
                    self.$utils.validate(error.response, self)
                });
        },
        methods: {
            fillSongs: function () {
                this.quoteSongs = [];
                var self = this;
                this.songs.forEach(function (song) {
                    if (typeof song === 'string') {
                        self.quoteSongs.push(JSON.parse(song))
                    } else {
                        self.quoteSongs.push(song)
                    }
                });
            },
            removeSong: function (song) {
                this.quoteSongs = this.quoteSongs.filter(item => item !== song);
                // localStorage.setItem('quoteSongs', JSON.stringify(this.quoteSongs))
                this.cart.commit('remove', song)
            },
            quotate: function () {
                this.dialog = false;
                if (this.$refs.formQ.validate()) {
                    var quotation = {
                        'formData': this.formData,
                        'formUser': this.formUser,
                        'songs': this.quoteSongs
                    };
                    var self = this;
                    this.$axios.post('cotizar', {quotation: quotation})
                        .then(function (response) {
                            if (response.status === 201) {
                                localStorage.setItem('quoteSongs', JSON.stringify([]));
                                localStorage.setItem('quoteData', JSON.stringify({}));
                                self.$router.push('/inicio?cotizacion')
                            }
                        })
                        .catch(function (error) {
                            self.$utils.validate(error.response, self)
                        });
                }
            }
        },
        computed: {
            songs: function () {
                return this.cart ? this.cart.state.songs : [];
            }
        }
    }
</script>

<style scoped>
    .content {
        padding: 1rem 2rem;
    }

    .gLayout {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 1.5rem;
        padding: 1rem .5rem;
    }

    .form-right {
        position: sticky;
        top: calc(74px + 1rem);
    }

    p {
        font-size: 1.1rem;
        line-height: 1.4;
    }

    @media (max-width: 950px) {
        .gLayout {
            display: block;
            padding: 1rem 0;
            margin-bottom: 150px;
        }

        .form-right {
            position: fixed;
            display: flex;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
            top: auto;
            background-color: #fff;
            flex-direction: row-reverse;
            box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);
            padding: .75rem;
        }

        .form-right .form-group {
            margin: 0;
            flex: 100%;
        }

        .form-right .btn {
            padding: .75rem .5rem;
            font-size: .8rem;
        }

        .form-right .btn-outline {
            margin-right: .75rem;
        }

        .form-right .btn-rojo {
            border: 1px solid transparent;
        }
    }

</style>
