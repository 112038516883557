import Vue from 'vue'
import VueRouter from 'vue-router'
import ClientMain from "@/views/client/ClientMain";
import ClientSong from "@/views/client/ClientSong";
import SplashPage from "@/views/splash/SplashPage";
import ClientListas from "../views/client/ClientListas";
import ClientListaInterna from "../views/client/ClientListaInterna";
import ClientProfile from "../views/client/ClientProfile";
import ClientSubCategory from '../views/client/ClientSubCategory'
import LoginPage from "../views/login/LoginPage";
import AdminMain from "../views/admin/AdminMain";
import AdminCatalog from "../views/admin/AdminCatalog";
import AdminCategory from "../views/admin/AdminCategory";
import AdminSong from "../views/admin/AdminSong";
import AdminUpload from "../views/admin/AdminUpload";
import AdminSolicitud from "../views/admin/AdminSolicitud";
import AdminUsers from "../views/admin/AdminUsers";
import AdminUser from "../views/admin/AdminUser";
import ClientQuote from "../views/client/ClientQuote";
import AdminSubCategory from "../views/admin/AdminSubCategory";
import AdminSubCategoryInterna from "../views/admin/AdminSubCategoryInterna";
import AdminListaInterna from "../views/admin/AdminListaInterna";
import RecoveryForm from "../views/RecoveryForm";
import AdminAnalytics from "../views/admin/AdminAnalytics";
import axios from 'axios';

Vue.use(VueRouter);

const routes = [
    {path: '/', name: 'SplashPage', component: SplashPage},
    {path: '/inicio', name: 'ClientMain', component: ClientMain},
    {path: '/generos', name: 'ClientMainGenre', component: ClientMain},
    {path: '/generos/:slug', name: 'ClientMainGenreSlug', component: ClientMain},
    {path: '/artistas/:slug', name: 'ClientMainArtistSlug', component: ClientMain},
    {path: '/decadas', name: 'ClientMainDecades', component: ClientMain},
    {path: '/destacados', name: 'ClientMainHighlight', component: ClientMain},
    {path: '/cancion/:id', name: 'ClientSong', component: ClientSong},
    {path: '/listas', name: 'ClientListas', component: ClientListas},
    {path: '/listas/:id', name: 'ClientListaInterna', component: ClientListaInterna},
    {path: '/perfil', name: 'ClientProfile', component: ClientProfile},
    {path: '/catalogo/:slug', name: 'ClientSubCategory', component: ClientSubCategory},
    {path: '/solicitar-cotizacion', name: 'ClientQuote', component: ClientQuote},
    {path: '/login', name: 'LoginPage', component: LoginPage},
    {path: '/admin', name: 'AdminMain', component: AdminMain},
    {path: '/admin/solicitud/:id', name: 'AdminSolicitud', component: AdminSolicitud},
    {path: '/admin/catalogo', name: 'AdminCatalogo', component: AdminCatalog},
    {path: '/admin/catalogo/:id', name: 'AdminSong', component: AdminSong},
    {path: '/admin/categorias', name: 'AdminCategory', component: AdminCategory},
    {path: '/admin/categorias/:id', name: 'AdminSubCategory', component: AdminSubCategory},
    {path: '/admin/categorias/:id/:slug', name: 'AdminSubCategoryInterna', component: AdminSubCategoryInterna},
    {path: '/admin/cargar', name: 'AdminUpload', component: AdminUpload},
    {path: '/admin/usuarios', name: 'AdminUsers', component: AdminUsers},
    {path: '/admin/usuarios/:id', name: 'AdminUser', component: AdminUser},
    {path: '/admin/listas/:id', name: 'AdminListaInterna', component: AdminListaInterna},
    {path: '/admin/analytics', name: 'AdminAnalytics', component: AdminAnalytics},
    {path: '/gestionar-contrasena', name: 'RecoveryForm', component: RecoveryForm},
    {path: '*', redirect: {name: 'SplashPage'}}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if(to.name === 'RecoveryForm') {
        next();
    }
    else {
        var adminUrl = '';
        if (to.path.startsWith('/admin')) {
            adminUrl = 'admin-app/'
        }

        axios.get(process.env.VUE_APP_BACKEND + adminUrl + 'check-login', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (to.name === 'LoginPage') {
                    next(response.data.reroute)
                }
                if (to.path.startsWith('/admin')) {
                    var path = to.path.replace('/admin', '').replace('/', '');
                    if (response.data.group === '*') {
                        if(response.status===401) {
                            localStorage.setItem('token', '');
                            next('/login')
                        }
                    }
                    else if (response.data.group.includes(path)) {
                        next('/admin/catalogo')
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    if (to.name === 'LoginPage' || to.name === 'SplashPage') {
                        next()
                    } else {
                        next({name: 'LoginPage'})
                    }
                }
            })
    }
})

export default router
