<template>
    <div>
        <aside class="action-overlay" :class="{active: active.value}">
            <div class="header">
                <div class="box-center double-spaced">
                    <img height="17" src="@/assets/icons/agregar-blanco.svg">
                    <span>Crear nueva playlist</span>
                </div>
            </div>
            <div class="body">
                <div class="block">
                    <div class="block">
                        <v-form class="form-group" ref="form">
                            <v-text-field :error-messages="errorMessages.name" v-model="newObject"
                                          :rules="[rules.required]"
                                          label="Nombre de la playlist"></v-text-field>
                        </v-form>
                        <br>
                        <div @click="save()" class="form-group box-center">
                            <div class="btn-form btn btn-rojo">GUARDAR</div>
                        </div>
                        <div class="form-group box-center" @click="active.value=false">
                            <div class="btn-form btn btn-outline">CANCELAR</div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <div class="back-overlay" :class="{active: active.value}" @click="active.value=false"></div>
    </div>
</template>

<script>
    export default {
        name: "FormAsidePlaylist",
        props: ['active'],
        data: () => ({
            rules: {
                required: value => !!value || 'Requerido.'
            },
            creating: false,
            newObject: '',
            errorMessages: {
                name: []
            }
        }),
        methods: {
            save: function () {
                if(this.$refs.form.validate()) {
                    this.createPlaylist();
                }
            },
            createPlaylist: function () {
                if (this.$refs.form.validate()) {
                    var self = this;
                    this.$axios.post('crear-playlist', {name: this.newObject})
                        .then(function (response) {
                            if (response.data.status === 201) {
                                self.active.value = false;
                                self.$emit('refresh');
                            }
                        })
                        .catch(function (error) {
                            self.$utils.validate(error.response, self)
                        });
                }
            }
        }
    }
</script>

<style scoped>
    .action-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 350px;
        background-color: #fff;
        z-index: 99;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        transform: translateX(100%);
        transition: 0.2s all ease-in-out;
    }

    .action-overlay.active {
        transform: translateX(0%);
    }

    .back-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        z-index: 98;
    }

    .back-overlay.active {
        bottom: 0;
        opacity: 1;
    }

    .header {
        background-color: #2B4959;
        padding: 1.5rem 1rem;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 600;
    }

    dd, dt {
        border-bottom: 1px solid #eaeaea;
        padding: 1rem 0;
    }

    dd {
        color: #5788A8;
        cursor: pointer;
        padding-left: .5rem;
        transition: .2s;
    }

    dd:hover {
        background-color: #f7f8f9;
    }

    .action-overlay {
        display: flex;
        flex-direction: column;
    }

    .action-overlay .body {
        flex: 100%;
    }

    .actions .block {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .btn-form {
        width: 200px;
        text-align: center;
    }

</style>
