<template>
  <section>
    <div class="container-fluid regular">
      <div class="gIndustry grid2 gap30 rgrid">
        <div class="left">
          <h2>{{ content.title }}</h2>
          <p>{{ content.description }}</p>
        </div>
        <div class="right">
          <div class="gridSellos">
            <template v-for="item in content.icons">
              <div class="box-center">
                <div class="icon">
                  <img height="80" :src="item">
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "SplashIndustry",
  props: [ 'content' ]
}
</script>

<style scoped>
section {
  padding: 5rem 0;
}

.gIndustry {
  align-items: center;
}

.justify-space-around {
  justify-content: space-around;
}

h2 {
  color: #2B4959;
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

p {
  line-height: 1.5;
  font-weight: lighter;
  font-size: 1.1rem;
  color: #3A3A3A;
}

@media (max-width: 950px) {
  section {
    padding: 1.5rem 0;
  }

  .icon img {
    max-height: 60px;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.gridSellos {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
</style>
