<template>
    <main v-if="song">
        <SimpleHeader :link="false" :title="song.title"></SimpleHeader>
        <section>
            <div class="container-fluid">
                <div class="gridSong">
                    <div class="left">
                        <div class="player-wrap">
                            <Player v-if="song" :song="song"></Player>
                            <div class="block form-player">
                                <div class="form-group">
                                    <div class="btn btn-outline box-left spaced" @click="addSingleSong">
                                        <img src="@/assets/icons/agregar-boton.svg">
                                        <span>AGREGAR A UNA LISTA</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="btn btn-outline box-left spaced"
                                         @click="addToQuote()">
                                        <img src="@/assets/icons/agregar-boton.svg">
                                        <span>AGREGAR A COTIZACIÓN</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="gContent">
                            <div class="content-left">
                                <FormSong :cancion="song" :readonly="true"></FormSong>
                            </div>
                            <div class="content-right" v-if="song.lyrics !== ''">
                                <div class="form-group">
                                    <label>Letra</label>
                                    <div class="letra">
                                        <pre>{{song.lyrics}}</pre>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="wrapRelated">
                            <h3>Canciones relacionadas</h3>
                            <RelatesSongs :records="records" v-on:clickSong="getSong"></RelatesSongs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <AgregarLista :active="actionOverlay" :records="sendSongs"></AgregarLista>
        <v-snackbar v-model="snackbar" :timeout="4000">
            Canción agregada a cotización
            <v-btn color="blue" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
    </main>

</template>

<script>
    import SimpleHeader from "@/components/SimpleHeader";
    import Player from "../../components/widgets/Player";
    import FormSong from "@/components/forms/FormSong";
    import RelatesSongs from "@/components/tables/RelatesSongs";
    import AgregarLista from "../../components/AgregarLista";
    import HeaderClient from "../../components/HeaderClient";
    import cart from "../../main";

    export default {
        name: "ClientSong",
        data() {
            return {
                song: undefined,
                records: [],
                actionOverlay: {value: false},
                sendSongs: {value: []},
                loading: false,
                snackbar: false,
                cart
            }
        },
        components: {HeaderClient, RelatesSongs, FormSong, Player, SimpleHeader, AgregarLista},
        mounted() {
            this.getSong(this.$route.params.id);
        },
        methods: {
            addSingleSong: function () {
                this.sendSongs = {value: []};
                this.sendSongs.value.push([JSON.stringify(this.song),]);
                this.openModalList();
            },
            addToQuote: function () {
                this.cart.commit('add', this.song);
                this.snackbar = true;
            },
            openModalList: function () {
                if (this.actionOverlay.value === false) this.actionOverlay.value = true;
            },
            getSong: function (id) {
                var self = this;
                this.loading = true;
                this.$axios.get(`/cancion/${id}`)
                    .then((response) => {
                        this.loading = false;
                        this.song = response.data.song;
                        this.records = response.data.related_songs;
                        window.scrollTo(0, 0);
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$utils.validate(error.response, self)
                    });
            }
        }
    }
</script>

<style scoped>

    .player-wrap {
        position: sticky;
        top: calc(74px + 2rem);
    }

    .gridSong {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 2rem 1rem;
        grid-gap: 4rem;
    }

    .gContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4rem;
    }

    .letra {
        padding: 1rem;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        background-color: #FBFBFB;
    }

    .wrapRelated {
        margin-top: 3rem;
    }

    .wrapRelated h3 {
        border-bottom: 1px solid;
        padding-bottom: .75rem;
        margin-bottom: 2.5rem;
    }

    .form-player {
        margin-top: 1.5rem;
    }

    @media (max-width: 950px) {
        .gridSong {
            grid-template-columns: 1fr;
            padding:1rem 0;
            grid-gap: 1rem;
            margin-bottom:150px;
        }

        .player{
            margin: 0 auto;
        }

        .player-wrap{
            z-index: 2;
        }

        .form-player{
            position: fixed;
            bottom:0;
            left:0;
            right:0;
            background: #fff;
            z-index:2;
            display: flex;
        }

        .gContent{
            display: block;
        }

        .form-player{
            padding: .75rem;
            box-shadow: 0 -3px 6px rgba(0,0,0,0.1);
        }

        .form-player .form-group{
            margin-bottom:0;
            flex:100%;
        }

        .form-player .form-group:first-child{
            margin-right:.75rem;
        }

        .form-player .btn img{
            display: none;
        }

        .form-player .btn{
            padding:.75rem .5rem;
            font-size: .75rem;
            justify-content: center;
            background-color: #3c627a;
            border:none;
            color:#fff;
        }
    }

    pre{
        font-size: .9rem;
    }

</style>
