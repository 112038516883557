<template>
    <footer class="pad20">
        <div class="container-fluid regular">
            <div class="gFooter">
                <div>
                    <img height="50" src="@/assets/pix/landing/logo.png">
                </div>
                <div>
                    <Rrss :rrss="rrss"></Rrss>
                </div>
                <div class="box-right">
                    <div class="w300">
                        <dd>Contacto</dd>
                        <dd>jgonzalez@fme.com.co</dd>
                        <dd>Tel: +(57) 601 313 6130 Ext: 4050</dd>
                        <dd>Calle 74 # 11-81 Piso 7</dd>
                        <dd>Bogotá, Colombia</dd>
                    </div>
                </div>
            </div>
        </div>
    </footer>

</template>

<script>
    import Rrss from "./Rrss";

    export default {
        name: "SplashFooter",
        props: ['rrss'],
        components: {Rrss}
    }
</script>

<style scoped>
    footer {
        border-top: 3px solid #BB1735;
    }

    .gFooter {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2rem;
        align-items: center;
    }

    dd{
        margin-bottom: .5rem;
        font-weight: 600;
        color: #2B4959;
    }

    @media(max-width: 950px){
        .gFooter{
            grid-template-columns: 100%;
        }

        .gFooter>div{
            justify-content: center;
            display: flex;
            text-align: center;
        }
    }


</style>
