<template>
    <div v-if="home">
        <SplashHeader v-on:contact="dialog.value=true" :rrss="home.rrss"></SplashHeader>
        <SplashCover v-on:contact="dialog.value=true"
                     :video="home.video"
                     :title="home.title"
                     :description="home.description">
        </SplashCover>
        <SplashPlaylists v-if="home.play_lists" :playlists="home.play_lists"></SplashPlaylists>
        <SplashFeatures v-on:contact="dialog.value=true" :features="home.features"></SplashFeatures>
        <SplashCatalog v-if="home.productions" :productions="home.productions" :intro="home.production_description"></SplashCatalog>
        <SplashDerechos v-if="home.rights_banner" :content-data="home.rights_banner"></SplashDerechos>
        <SplashIndustry v-if="home.section_industry" :content="home.section_industry"></SplashIndustry>
        <SplashFindus v-if="home.section_find_us" :content="home.section_find_us"></SplashFindus>
        <SplashFooter :rrss="home.rrss"></SplashFooter>
        <form-registro :dialog="dialog"></form-registro>
    </div>

</template>

<script>
    import SplashHeader from "./sections/SplashHeader";
    import SplashCover from "./sections/SplashCover";
    import SplashPlaylists from "./sections/SplashPlaylists";
    import SplashFeatures from "./sections/SplashFeatures";
    import SplashCatalog from "./sections/SplashCatalog";
    import SplashDerechos from "./sections/SplashDerechos";
    import SplashIndustry from "./sections/SplashIndustry";
    import SplashFindus from "./sections/SplashFindus";
    import SplashFooter from "./sections/SplashFooter";
    import FormRegistro from "../../components/forms/FormRegistro";

    export default {
        name: "SplashPage",
        components: {
            FormRegistro,
            SplashFooter,
            SplashFindus,
            SplashIndustry,
            SplashDerechos, SplashCatalog, SplashFeatures, SplashPlaylists, SplashCover, SplashHeader
        },
        data() {
            return {
                home: undefined,
                dialog: {value:false}
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData: function () {
                var self = this;
                this.$axios.get('home').then((response) => {
                    this.home = response.data;
                }).catch(() => {
                });
            }
        }
    }
</script>

<style scoped>

</style>
