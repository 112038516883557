<template>
    <section class="back-gris">
        <div class="container-fluid">
            <div class="box-left h-100 extended">
                <div class="box-left title">
                    <a  @click="$router.go(-1)" v-if="!link && ($route.path.includes('/catalogo/') ||$route.path.includes('/cancion/') || $route.path.includes('/listas/'))">
                        <img height="25" src="@/assets/icons/atras.svg">
                    </a>
                    <router-link v-if="link" :to="back">
                        <img height="25" src="@/assets/icons/atras.svg">
                    </router-link>
                    <h3>{{ title }}</h3>
                    <div v-if="note" class="note back-gris rounded">{{ note }}</div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        props: {
            title: {type: String}, note: {type: String},
            link:{type: Boolean, default: true},
            back: {
                type: String,
                default: '/inicio'
            },
        },
        name: "SimpleHeader"
    }
</script>

<style scoped>
    header {
        padding: .75rem 1rem;
        min-height: 74px;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 2;
    }

    .btn-cerrar {
        border-radius: 2rem;
        font-size: .85rem;
        cursor: pointer;
    }

    h3 {
        margin: 0;
        line-height: 50px;
        color: #2B4959;
        margin-left:.5rem;
    }

    .title img {
        margin-right: .25rem;
    }

    .note {
        padding: .5rem 1rem;
        margin-left: 2rem;
        color: #333333;
    }

</style>
