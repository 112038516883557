<template>
    <v-dialog v-model="dialog.value" max-width="350">
        <v-card class="text-center">
            <div class="block">
                <h3 v-if="title">{{title}}</h3>
                <p v-if="text">{{text}}</p>
                <v-card-actions>
                    <div class="box-center double-spaced w-100">
                    <v-btn class="btn btn-outline" text @click="$emit('no')">
                        {{ noText }}
                    </v-btn>
                    <v-btn class="btn btn-rojo" text @click="$emit('yes')">
                        {{ yesText }}
                    </v-btn>
                    </div>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "YesNoDialog",
        props: {
            dialog: {
                type: Object
            },
            text: {
                type: String,
                default: undefined
            },
            title: {
                type: String,
                default: undefined
            },
            yesText: {
                type: String,
                default: 'Si'
            },
            noText: {
                type: String,
                default: 'No'
            }
        }
    }
</script>

<style scoped>
    .btn-rojo {
        color: white;
        min-width: 130px !important;
    }

    .btn-outline {
        color: #24404F;
        min-width: 130px !important;
    }
</style>
