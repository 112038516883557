<template>
    <section>
        <div class="container-fluid pad">
            <div  class="tabs box-left spaced">
                <div v-for="tab in tabs" :class="{'active':value===tab.slug }" class="tab" @click="$emit('activate', tab.slug)">
                    {{ tab.label }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Tabs",
        props:['tabs', 'value'],
        model:{
            event: 'activate'
        }
    }
</script>

<style scoped>

</style>
