<template>
    <div>
        <aside class="action-overlay" :class="{active: active.value}">
            <div class="header">
                <div v-if="!createCat" class="box-center double-spaced">
                    <img height="17" src="@/assets/icons/agregar-blanco.svg">
                    <span>Agregar canciones a una categoría</span>
                </div>
                <div v-if="createCat" class="box-center">
                    <span>Crear nueva categoría</span>
                </div>
            </div>
            <div class="body">
                <div v-if="step===1" class="block">
                    <dt>Selecciona la categoría de tu preferencia</dt>
                    <dd v-for="cat in menu.categories" @click="step=2; ids.cat=cat.value">{{ cat.name }}</dd>
                </div>
                <div v-if="step===2" class="block">
                    <dt>Selecciona la subcategoría de tu preferencia</dt>
                    <dd v-for="sub in menu.subcategories[ids.cat]" @click="addSong(sub.id)">{{ sub.name }}</dd>
                </div>
            </div>
            <div class="actions">
                <div class="block box-center">
                    <div v-if="step===1" class="btn btn-rojo box-left spaced" @click="createCat.value=true">
                        <img height="15" src="@/assets/icons/plus.svg">
                        <span>CREAR NUEVA CATEGORÍA</span>
                    </div>
                    <div v-if="step===2" class="btn btn-rojo box-left spaced" @click="createSubCat.value=true">
                        <img height="15" src="@/assets/icons/plus.svg">
                        <span>CREAR NUEVA SUBCATEGORÍA</span>
                    </div>
                </div>
            </div>
        </aside>
        <div class="back-overlay" :class="{active: active.value}" @click="active.value=false"></div>
        <form-aside-new v-on:saved="refresh" :active="createCat" :new-object="cat" object="category"></form-aside-new>
        <form-aside-new v-on:saved="refresh" :cat-id="ids.cat"  :active="createSubCat" :new-object="subcat" object="subcategory"></form-aside-new>
    </div>
</template>

<script>
    import FormAsideNew from "./forms/FormAsideNew";

    export default {
        components: {FormAsideNew},
        props: ['active', 'records'],
        name: "AgregarACatalogo",
        data: () => ({
            step: 1,
            steps: {
                1: 'categoría',
                2: 'subcategoría'
            },
            ids: {
                cat: -1,
                subcat: -1
            },
            cat: {
                name: ''
            },
            createCat: {value: false},
            subcat: {
                name: '',
                img: [],
                slug: '',
                highlight: false,
                is_active: false
            },
            createSubCat: {value: false},
            newPlaylist: '',
            menu: {},
            object: '',
            rules: {
                required: value => !!value || 'Requerido.'
            }
        }),
        mounted() {
            this.refresh();
        },
        methods: {
            refresh: function () {
                var self = this;
                this.$axios.get('admin-app/all-categorias')
                    .then(function (response) {
                        self.menu = response.data.menu;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, this);
                    })
            },
            addSong: function (id) {
                var self = this;
                this.$axios.post(`admin-app/subcategoria/${id}/anadir-cancion`, {
                    ids: self.records
                })
                    .then(function (response) {
                        self.active.value = false
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, this);
                    })
            }
        },
        watch: {
            'active.value': function () {
                this.step = 1;
            }
        }
    }
</script>

<style scoped>
    .action-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 350px;
        background-color: #fff;
        z-index: 99;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        transform: translateX(100%);
        transition: 0.2s all ease-in-out;
    }

    .action-overlay.active {
        transform: translateX(0%);
    }

    .back-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        z-index: 98;
    }

    .back-overlay.active {
        bottom: 0;
        opacity: 1;
    }

    .header {
        background-color: #2B4959;
        padding: 1.5rem 1rem;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 600;
    }

    dd, dt {
        border-bottom: 1px solid #eaeaea;
        padding: 1rem 0;
    }

    dd {
        color: #5788A8;
        cursor: pointer;
        padding-left: .5rem;
        transition: .2s;
    }

    dd:hover {
        background-color: #f7f8f9;
    }

    .action-overlay {
        display: flex;
        flex-direction: column;
    }

    .action-overlay .body {
        flex: 100%;
    }

    .actions .block {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .btn-form {
        width: 200px;
        text-align: center;
    }
</style>
