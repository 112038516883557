<template>
    <main>
        <AdminSearch v-on:search="refresh" :search="searchInput" :actions="false" :total="requestCount"></AdminSearch>
        <Tabs :tabs="tabs" v-model="active"></Tabs>
        <section>
            <div class="container-fluid">
                <template v-if="active==='activas'">
                    <p>Solicitudes Activas</p>
                </template>
                <template v-if="active==='archivadas'">
                    <p>Solicitudes Archivadas</p>
                </template>
            </div>
        </section>
        <AdminRequests :active="active" v-on:download="downloadRequests" v-on:change="refresh" :requests="requests"
                       :checked-list="checkedRequests" :pagination="pagination"></AdminRequests>
        <v-text-field color="blue" loading disabled v-if="loading"></v-text-field>

        <loading-dialog :dialog="downloadDialog" text="Descargando"></loading-dialog>
    </main>

</template>

<script>
    import AdminHeader from "./partials/AdminHeader";
    import AdminSearch from "./partials/AdminSearch";
    import Tabs from "../../components/Tabs";
    import AdminRequests from "./partials/AdminRequests";
    import LoadingDialog from "../../components/dialogs/LoadingDialog";

    const FileDownload = require('js-file-download');

    export default {
        name: "AdminMain",
        components: {LoadingDialog, AdminRequests, Tabs, AdminSearch, AdminHeader},
        data() {
            return {
                tabs: [
                    {slug: 'activas', label: 'ACTIVAS'},
                    {slug: 'archivadas', label: 'ARCHIVADAS'}
                ],
                downloadDialog: false,
                loading: false,
                active: 'activas',
                requests: [],
                requestCount: 0,
                searchInput: {value: ''},
                checkedRequests: {value: []},
                pagination: {page: 1, pages: 1}
            }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            getUrl: function () {
                var url = '';
                this.active === 'activas' ? url = 'no-archivados' : url = 'archivados';
                return url
            },
            refresh: function () {
                this.loading = true;
                this.requests = [];
                var self = this;
                this.$axios.get(`/admin-app/requests/${this.getUrl()}`, {
                    params: {
                        q: this.searchInput.value,
                        p: this.pagination.page
                    }
                })
                    .then(function (response) {
                        self.requests = response.data.requests;
                        self.requestCount = response.data.count;
                        self.pagination = response.data.pagination;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self);
                        self.loading = false;
                    });
            },
            downloadRequests: function () {
                this.downloadDialog = true;
                var self = this;
                this.$axios.get(`/admin-app/requests/${this.getUrl()}/descargar`, {
                    params: {
                        q: this.searchInput.value,
                        ids: this.checkedRequests.value
                    }
                })
                    .then(function (response) {
                        FileDownload(response.data, 'Cotizaciones.csv');
                        self.downloadDialog = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            }
        },
        watch: {
            'active': function () {
                this.refresh()
            }
        }
    }
</script>

<style scoped>

</style>
