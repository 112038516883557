<template>
    <div class="btn btn-outline cerrar-sesion box-center btn-cerrar"
         @click="$utils.logout(); $router.push('/')">
        <img height="15" src="@/assets/icons/cerrar-sesion.svg">
    </div>
</template>

<script>
    export default {
        name: "Logout"
    }
</script>

<style scoped>
    .btn-cerrar {
        border-radius: 2rem;
        font-size: .85rem;
        cursor: pointer;
    }

    .cerrar-sesion {
        width: 40px;
        height: 40px;
        padding: 0;
        position: relative;
    }

    .cerrar-sesion img {
        position: relative;
        top: -2px;
        width: 12.5px;
        height: 15px;
    }

    .cerrar-sesion:after {
        content: "CERRAR SESIÓN";
        position: absolute;
        width: max-content;
        border: 1px solid #eaeaea;
        padding: .1rem .25rem;
        font-size: .8rem;
        border-radius: 3px;
        display: none;
        bottom: -1.6rem;
        left: -60px;
        background-color: #fff;
    }

    .cerrar-sesion:hover:after {
        display: block;
    }
</style>
