<template>
  <section>
    <div class="container-fluid">
      <div class="box-left">
        <div class="btn btn-outline box-left spaced" @click="$emit('download')">
          <i class="fa fa-download"></i>
          <span>DESCARGAR CSV</span>
        </div>
      </div>
    </div>
      <div class="container-fluid">
        <div class="pad10 w900 table-requests">
          <div class="gTableHeader">
            <div>
              <v-checkbox @change="selectAll" v-model="allSelected"></v-checkbox>
              Fecha de solicitud
            </div>
            <div>Solicitante</div>
            <div>Empresa solicitante</div>
            <div>Canciones</div>
            <div>Acciones</div>
          </div>
          <div class="gRecordItem" v-for="item in requests">
            <div>

              <div class="box-left double-spaced">
                <v-checkbox type="checkbox" :value="item.id" v-model="checkedList.value"></v-checkbox>
                <span class="naui-date">{{ item.date }}</span>
              </div>
            </div>
            <div class="text-center">
              {{ item.name }}
            </div>
            <div class="text-center">
              {{ item.company }}
            </div>
            <div class="text-center">
              {{ item.count }}
            </div>
            <div>
              <div class="box-center spaced actions">
                <div>
                  <router-link :to="{name:'AdminSolicitud', params: {id:item.id}}">
                    <img height="25" src="@/assets/icons/lupa-roja.svg">
                  </router-link>
                </div>
                <div @click="selectedId = item.id; snackbar = true">
                  <img height="25" src="@/assets/icons/archivar.svg" v-if="active==='activas'">
                  <img height="25" src="@/assets/icons/desarchivar.svg" v-if="active==='archivadas'">
                </div>
              </div>
            </div>
          </div>
          <v-pagination v-if="pagination !== undefined && pagination.pages > 1"
                        v-on:next="$emit('change')"
                        v-on:previous="$emit('change')"
                        v-on:input="$emit('change')"
                        v-model="pagination.page"
                        class="my-4"
                        :length="pagination.pages"
                        total-visible="10"
          ></v-pagination>
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="10000">
        ¿Desea cambiar el estado de la solicitud?
        <v-btn color="green" text @click="archiveRequest(selectedId); snackbar = false">Sí</v-btn>
        <v-btn color="red" text @click="snackbar = false">Cerrar</v-btn>
      </v-snackbar>
  </section>

</template>

<script>
export default {
  name: "AdminRequests",
  props: [ 'title', 'requests', 'active', 'checkedList', 'pagination', 'active' ],
  data() {
    return {
      allSelected: false,
      selectedId: undefined,
      snackbar: false,
    }
  },
  methods: {
    archiveRequest: function (id) {
      var self = this;
      this.$axios.post(`/admin-app/requests/toggle-archivado`, { id: id })
          .then(function (response) {
            self.$emit('change')
          })
          .catch(function (error) {
            self.$utils.validate(error.response, self)
          });
    },
    selectAll: function () {
      var requestIds = [];
      this.requests.forEach(function (request) {
        requestIds.push(request.id)
      });
      this.checkedList.value = this.checkedList.value.filter(function (request) {
        return !requestIds.includes(request)
      });
      if (this.allSelected) {
        var selection = [];
        this.requests.forEach(function (request) {
          selection.push(request.id);
        });
        this.checkedList.value.push(...selection);
      }
    }
  },
  watch: {
    'requests': function (newVal) {
      if (newVal.length > 0) {
        var selection = [];
        var selected = true;
        newVal.forEach(function (request) {
          selection.push(request.id);
        });
        for (let i = 0; i < selection.length; i++) {
          if (!this.checkedList.value.includes(selection[i])) {
            selected = false;
            break;
          }
        }
        this.allSelected = selected
      }
    }
  }
}
</script>

<style scoped>
.actions div {
  margin: 0 .5rem;
  cursor: pointer;
}

.gTableHeader, .gRecordItem {
  grid-template-columns: 20% 20% 30% 15% 15%;
}

.gRecordItem {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .gTableHeader{
    min-width: 700px;
    top:0;
  }
  .gRecordItem{
    min-width: 700px;
  }

  .table-requests{
    overflow-x: scroll;
    width: 100%;
  }
}


</style>
