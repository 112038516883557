<template>
    <main class="box-center">
        <div class="back">
            <simple-header title="Inicio" :back="'/'"></simple-header>
        </div>
        <div class="login-box rounded naui-shadow">
            <div class="box-center">
                <img height="70" src="@/assets/pix/landing/logo.png">
            </div>
            <div class="login-form">
                <v-text-field
                        label="Usuario"
                        v-model="credentials.user"
                        :rules="[rules.required]"
                        outlined
                ></v-text-field>
                <v-text-field
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show3 ? 'text' : 'password'"
                        name="input-10-2"
                        label="Contraseña"
                        v-model="credentials.password"
                        class="input-group--focused"
                        @click:append="show3 = !show3"
                        outlined
                ></v-text-field>
                <div class="btn btn-rojo text-center" @click="login">
                    INGRESAR
                </div>
                <div class="recovery pad text-center">
                    <p @click="recoverPassword.value = true">¿Olvidó su contraseña?</p>
                </div>
            </div>
        </div>
        <v-snackbar v-model="snack" :timeout="4000">
            {{message}}
            <v-btn color="blue" text @click="snack = false">Cerrar</v-btn>
        </v-snackbar>

        <password-recovery :dialog="recoverPassword" v-on:recover="recover()"></password-recovery>

    </main>
</template>

<script>
    import PasswordRecovery from "../../components/dialogs/PasswordRecovery";
    import * as jwt_decode from 'jwt-decode';
    import SimpleHeader from "../../components/SimpleHeader";
    import cart from "../../main";

    export default {
        name: "LoginPage",
        components: {SimpleHeader, PasswordRecovery},
        data() {
            return {
                cart,
                rules: {
                    required: value => !!value || 'Requerido.'
                },
                recoverPassword: {value: false},
                message: '',
                show3: false,
                snack: false,
                credentials: {
                    user: '',
                    password: ''
                }
            }
        },
        mounted() {
            if (localStorage.getItem('token') && localStorage.getItem('token').length > 0) {
                this.$router.push('/inicio')
            }
        },
        methods: {
            login: function (event) {
                var self = this;
                if (this.username !== '' && this.password !== '') {
                    this.$axios.post('login', {
                        username: self.credentials.user,
                        password: self.credentials.password
                    }).then(function (response) {
                        self.message = '';
                        localStorage.setItem('token', response.data.token);
                        const payload = jwt_decode(response.data.token);
                        self.cart.commit('refreshProhibited', payload.prohibited);
                        localStorage.setItem('prohibited', JSON.stringify(payload.prohibited));
                        if (payload.role === 'Cliente') {
                            self.$router.push('/inicio');
                        } else if (payload.role === 'Interno') {
                            self.$router.push('/admin');
                        }
                    }).catch(function (error) {
                        self.message = 'Usuario o contraseña incorrectos.';
                        self.snack = true;
                    })
                } else {
                }
            },
            recover: function () {
                var self = this;
                this.recoverPassword.value = false;
                this.$axios.post('recovery', {
                    username: this.recoverPassword.email
                }).then(function (response) {
                    self.snack = true;
                    self.message = 'Se ha enviado un correo con las instrucciones para restablecer la contraseña';
                }).catch(function (error) {
                    self.snack = true;
                    self.message = 'El correo no es valido.'
                })
            }
        }
    }
</script>

<style scoped>
    main {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1rem;
        background: linear-gradient(to bottom, #F3F4F6 50%, #828282 150%);
    }

    .login-box {
        padding: 2rem;
        background-color: #fff;
        min-width: 400px;
    }

    .login-form {
        margin-top: 1.5rem;
    }

    .btn-rojo {
        font-weight: 600;
        padding: 1rem;
    }

    .recovery {
        margin-top: .5rem;
    }

    .recovery p {
        text-decoration: underline;
        cursor: pointer;
        color: #24404F;
    }

    .back{
        position:fixed;
        top:0;
        left:0;
        right:0;
    }

    @media (max-width: 600px) {
        .login-box {
            min-width: 100%;
        }
    }

</style>
