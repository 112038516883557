<template>
    <section class="form">
        <v-form
            ref="form"
            lazy-validation
        >
            <fieldset>
                <legend>Características de la solicitud</legend>
                <div class="pad10">
                    <p><strong>Medios:</strong> Seleccione los medios en los que se utilizarán las obras</p>
                    <div class="box-left double-spaced checks">
                        <v-checkbox class="check" type="checkbox" color="gray" v-model="data.medios.internet" :readonly="readonly" label="Internet"></v-checkbox>
                        <v-checkbox class="check" type="checkbox" color="gray" v-model="data.medios.tv" :readonly="readonly" label="Televisión"></v-checkbox>
                        <v-checkbox class="check" type="checkbox" color="gray" v-model="data.medios.av" :readonly="readonly" label="Audiovisual"></v-checkbox>
                        <v-checkbox class="check" type="checkbox" color="gray" v-model="data.medios.radio" :readonly="readonly" label="Radio"></v-checkbox>

                    </div>
                    <div class="box-left double-spaced checks">
                        <v-checkbox class="check" type="checkbox" color="gray" v-model="data.medios.other_media.length > 0" :readonly="readonly" label="Otros"></v-checkbox>
                        <input class="naui-input others" type="text"
                               placeholder="Especifique los medios donde se utilizarán las obras"
                               v-model="data.medios.other_media" :readonly="readonly">
                    </div>
                </div>
                <br>
                <p><strong>Territorios:</strong> Seleccione los territorios en los que se utilizarán las obras</p>
                <div class="box-left double-spaced checks">
                    <v-checkbox class="check" type="checkbox" color="gray" v-model="data.territories.world" :readonly="readonly" label="Mundo"></v-checkbox>
                    <v-checkbox class="check" type="checkbox" color="gray" v-model="data.territories.colombia" :readonly="readonly" label="Colombia"></v-checkbox>
                </div>
                <div class="box-left double-spaced checks">
                    <v-checkbox class="check" type="checkbox" color="gray" v-model="data.territories.other_territories.length > 0" :readonly="readonly" label="Otros"></v-checkbox>
                    <input class="naui-input others" type="text"
                           placeholder="Especifique los territorios donde se difundirá las obras"
                           v-model="data.territories.other_territories" :readonly="readonly">
                </div>
            </fieldset>
            <fieldset>
                <legend>Información del solicitante</legend>
                <br>
                <div class="grid2 gap30 rgrid">
                    <div>
                        <div class="form-group">
                            <v-text-field v-model="user.name" :rules="[rules.required]" label="Nombre" :readonly="readonly"></v-text-field>
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <v-text-field v-model="user.email" :rules="[rules.required]"
                                          label="Correo electrónico" :readonly="readonly"></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="grid2 gap30 rgrid">
                    <div>
                        <div class="form-group">
                            <label>Documento de identidad</label>
                            <div class="box-left double-spaced">
                                <v-select
                                    :items="items"
                                    :readonly="readonly"
                                    v-model="user.doc_type"
                                    label="Tipo de documento"
                                >
                                </v-select>
                                <v-text-field v-model="user.doc_num" :rules="[rules.required]"
                                              label="Numero de identificación" :readonly="readonly"></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <v-text-field v-model="user.workplace" :rules="[rules.required]"
                                          label="Empresa donde trabaja" :readonly="readonly"></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="grid2 gap30">
                    <div>
                        <div class="form-group">
                            <v-text-field v-model="user.phone" :rules="[rules.required]"
                                          label="Teléfono de contacto" :readonly="readonly"></v-text-field>
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <v-text-field v-model="user.songplace" :rules="[rules.required]"
                                          label="Empresa que publicará las obras" :readonly="readonly"></v-text-field>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Observaciones</legend>
                <br>
                <v-textarea
                        outlined
                        label="Observaciones"
                        class="w-100"
                        v-model="data.observations"
                ></v-textarea>
            </fieldset>
        </v-form>
    </section>
</template>

<script>
    export default {
        name: "FormQuote",
        props: ['readonly', 'user', 'data'],
        data: () => ({
            items: [],
            rules: {
                required: value => !!value || 'Requerido.'
            }
        }),
        mounted() {
            const self = this;
            this.$axios.get('admin-app/id-types')
                .then(function (response) {
                    self.items = response.data;
                })
        },
        methods: {
            validate () {
                return !!this.$refs.form.validate();
            }
        }
    }
</script>

<style scoped>
    p {
        font-size: 1.1rem;
        line-height: 1.4;
    }

    fieldset {
        margin-top: 2rem;
    }

    .check input {
        margin-right: .25rem;
    }

    .check {
        margin-right: 2rem;
    }

    textarea {
        min-height: 100px;
        padding: .5rem .5rem;
    }

    .others {
        width: 500px;
    }

    @media(max-width: 950px){
        .checks{
            display: block;
        }

        .others{
            max-width: 330px;
        }

        .grid2{
            grid-gap: 0;
        }
    }

</style>
