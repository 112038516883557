<template>
    <section>
        <div class="container-fluid">
            <div class="pad10">
                <div class="w900">
                    <div class="gTableHeader">
                        <div class="text-left">Nombre</div>
                        <div>Perfil</div>
                        <div>Acciones</div>
                    </div>
                    <div class="gRecordItem" v-for="item in users">
                        <div class="text-left">
                            {{ item.name }}
                        </div>
                        <div class="text-center">
                            {{ item.perfil }}
                        </div>
                        <div>
                            <div class="box-center actions">
                                <router-link :to="{name:'AdminUser', params:{id: item.id}}">
                                    <img height="25" src="@/assets/icons/listas-lupa.svg" style="cursor: pointer">
                                </router-link>
                                <router-link :to="{name:'AdminUser', params:{id: item.id}, query:{editar:''}}">
                                    <img height="25" src="@/assets/icons/autoral.svg" style="cursor: pointer">
                                </router-link>
                                <div @click="selId = item.id; deleteDialog.value = true">
                                    <img height="25" src="@/assets/icons/borrar.svg" style="cursor: pointer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <v-pagination v-if="pagination !== undefined && pagination.pages > 1"
                              v-on:next="getUsers"
                              v-on:previous="getUsers"
                              v-on:input="getUsers"
                              v-model="pagination.page"
                              class="my-4"
                              :length="pagination.pages"
                              total-visible="10"
                ></v-pagination>
            </div>
            <yes-no-dialog
                    :dialog="deleteDialog"
                    title="¿Eliminar Usuario?" text="¿Esta seguro de que quiere eliminar este usuario?"
                    v-on:no="deleteDialog.value = false"
                    v-on:yes="deleteUser"
            ></yes-no-dialog>
        </div>
    </section>

</template>

<script>
    import YesNoDialog from "./dialogs/YesNoDialog";
    export default {
        name: "UsersTable",
        components: {YesNoDialog},
        props: ['search', 'filters', 'count'],
        data: () => ({
            deleteDialog: {value:false},
            users: [],
            selId: -1,
            pagination: {page:1, pages:1},
        }),
        mounted() {
            this.getUsers()
        },
        methods: {
            getUsers: function () {
                var self = this;
                this.$axios.get(`/admin-app/usuarios`, {
                    params: {
                        q: this.search.value,
                        p: this.pagination.page,
                        grupo: this.filters.perfil,
                    }
                })
                    .then(function (response) {
                        self.users = response.data.users;
                        self.pagination = response.data.pagination;
                        self.count.value = response.data.count;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    });
            },
            deleteUser: function () {
                var self = this;
                this.$axios.get(`/admin-app/usuarios/eliminar`, {
                    params: {
                        q: this.search.value,
                        p: this.pagination.page,
                        grupo: this.filters.perfil,
                    }
                })
                    .then(function (response) {
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    });
            }
        },
        watch: {
            'search.value': function () {
                var timer;
                // clears the timer on a call so there is always x seconds in between calls
                clearTimeout(this.timer);

                // if the timer resets before it hits 150ms it will not run
                this.timer = setTimeout(function () {
                    this.getUsers()
                }.bind(this), 300);
            },
            'filters.perfil': function () {
                this.getUsers();
            }
        }
    }
</script>

<style scoped>
    .actions div {
        margin: 0 .5rem;
        cursor: pointer;
    }
    .gRecordItem, .gTableHeader{
        grid-template-columns: 60% 20% 20%;
    }
</style>
