<template>
    <div>
        <simple-header :title="'Detalles'" :link="true" :back="'/admin/usuarios'"></simple-header>
        <section>
            <div class="container-fluid pad">
                <div class="tabs box-left spaced">
                    <div :class="{'active':active=='tab1'}" class="tab" @click="active='tab1'">
                        DATOS PERSONALES
                    </div>
                    <div :class="{'active':active=='tab2'}" class="tab" @click="active='tab2'">
                        LISTAS
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container-fluid">
                <div v-if="active==='tab1'">
                    <datos-personales :perfil="perfil" :items="items" :admin-edit="this.$route.query.hasOwnProperty('editar')"></datos-personales>
                </div>
                <div v-if="active==='tab2'">
                    <listas :listas="playlists"></listas>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import DatosPersonales from "../client/partials/DatosPersonales";
    import MisListas from "../client/partials/MisListas";
    import AdminHeader from "./partials/AdminHeader";
    import Listas from "./partials/Listas";
    import SimpleHeader from "../../components/SimpleHeader";
    export default {
        name: "AdminUser",
        components: {SimpleHeader, Listas, AdminHeader, MisListas, DatosPersonales},
        data() {
            return {
                perfil: {name: ''},
                items: [],
                playlists: [],
                active: 'tab1',
            }
        },
        mounted() {
            var self = this;
            this.$axios.get(`admin-app/usuarios/${this.$route.params.id}`)
                .then(function (response) {
                    self.perfil = response.data.user;
                    self.playlists = response.data.playlists;
                    self.items = response.data.id_types;
                })
                .catch(function (error) {
                    self.$utils.validate(error.response, self)
                })
        }
    }
</script>

<style scoped>

</style>
