<template>
    <main>
        <NavClient v-on:reroute="handleChangeTab" :active-tab="activeTab"></NavClient>
        <GenderTabs v-if="activeTab===2" v-on:refresh="genreRefresh" :tabs="genres"
                    :activeSlug="activeSlug"></GenderTabs>
        <decade-tabs v-if="activeTab===3" v-on:refresh="decadeRefresh" :tabs="decades"
                     :activeDecade="activeDecade"></decade-tabs>
        <FeaturedCategories v-if="activeTab===0 && categories.length > 0" :categories="categories"></FeaturedCategories>
        <section>
            <div class="container-fluid">
                <div class="box-left extended">
                    <FilterWidget v-on:filterChange="refresh"
                                  :filterOptions="filtrosOpciones" :filterValues="filtros"></FilterWidget>
                    <div class="action-records">
                        <ActionRecords v-on:addToList="addCheckedSongs" v-on:quote="requestQuotation"
                                       v-if="checkedSongs.value.length > 0"></ActionRecords>
                    </div>
                </div>
            </div>
        </section>
        <RecordTable :checkboxes="true" v-on:addToList="addSingleSong" v-on:change="refresh" :pagination="pagination"
                     :checked-songs="checkedSongs" :records="records"></RecordTable>
        <v-text-field color="#24404F" v-if="loading" loading disabled></v-text-field>
        <AgregarLista :active="actionOverlay" :records="sendSongs"></AgregarLista>
        <v-snackbar v-model="snackbar" :timeout="4000">
            Canciones agregadas cotización
            <v-btn color="blue" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
        <v-snackbar v-model="quoteSnack" :timeout="4000" :multi-line="true">
            Solicitud enviada. Nuestro equipo comercial se pondrá pronto en contacto con usted.
            <v-btn color="blue" text @click="quoteSnack = false">Cerrar</v-btn>
        </v-snackbar>
    </main>
</template>

<script>
    import HeaderClient from "@/components/HeaderClient";
    import NavClient from "@/components/NavClient";
    import FeaturedCategories from "@/components/FeaturedCategories";
    import FilterWidget from "@/components/widgets/FilterWidget";
    import RecordTable from "../../components/tables/RecordTable";
    import ActionRecords from "../../components/ActionRecords";
    import GenderTabs from "@/components/GenderTabs";
    import AgregarLista from "@/components/AgregarLista";
    import cart from "../../main";
    import DecadeTabs from "../../components/DecadeTabs";

    export default {
        name: "ClientMain",
        components: {
            DecadeTabs,
            RecordTable,
            FilterWidget,
            FeaturedCategories,
            NavClient,
            HeaderClient,
            ActionRecords,
            GenderTabs,
            AgregarLista
        },
        data: () => ({
            actionOverlay: {value: false},
            genres: [{
                'label': 'TODOS',
                'slug': ''
            }],
            decades: [],
            activeSlug: {value: ''},
            activeDecade: {value: ''},
            activeArtist: {value: ''},
            activeTab: 0,
            records: [],
            categories: [],
            querySearch: '',
            checkedSongs: {value: []},
            sendSongs: {value: []},
            pagination: {page: 1, pages: 1},
            loading: false,
            snackbar: false,
            filtrosOpciones: {
                derechos: [],
                autores: []
            },
            filtros: {
                autor: '',
                derechos: '',
                genero: ''
            },
            cart,
            quoteSnack: false,
            player: null
        }),
        mounted() {
            if (this.$route.query.hasOwnProperty('cotizacion')) {
                this.quoteSnack = true;
                this.$router.replace('/inicio');
            }
            this.recognizePath();
            this.getHeader();
            this.refresh();
        },
        methods: {
            requestQuotation: function () {
                var sendSongs = [];
                this.checkedSongs.value.forEach(function (jsong) {
                    sendSongs.push(JSON.parse(jsong))
                });
                // this.$utils.quotation(sendSongs);
                this.cart.commit('add', sendSongs);
                this.snackbar = true;
            },
            addCheckedSongs: function () {
                this.sendSongs = this.checkedSongs;
                this.openModalList();
            },
            addSingleSong: function (song) {
                this.sendSongs = {value: []};
                this.sendSongs.value.push(song);
                this.openModalList();
            },
            openModalList: function () {
                if (this.actionOverlay.value === false) this.actionOverlay.value = true;
            },

            handleChangeTab: function (tabIndex, router) {
                if (this.activeTab !== tabIndex) {
                    this.activeTab = tabIndex;
                    this.activeSlug.value = '';
                    this.$router.replace(router);
                    this.refresh();
                }
            },
            recognizePath: function () {
                if (this.$route.path.startsWith('/generos')) {
                    this.activeTab = 2;
                    if (this.$route.path.slice(1).split('/').length > 1) {
                        this.activeSlug.value = this.$route.path.slice(1).split('/')[1];
                    }
                } else if (this.$route.path.startsWith('/decadas')) {
                    this.activeTab = 3;
                    if (this.$route.path.slice(1).split('/').length > 1) {
                        this.activeDecade.value = this.$route.path.slice(1).split('/')[1];
                    }
                } else if (this.$route.path.startsWith('/destacados')) {
                    this.activeTab = 1;
                } else if (this.$route.path.startsWith('/artistas')) {
                    this.activeTab = 4;
                    this.activeArtist.value = this.$route.path.slice(1).split('/')[1];
                }
            },
            getHeader: function () {
                var self = this;
                const params = {
                  include_artists: this.activeTab !== 4,
                  include_genres: this.activeTab === 4
                }
                this.$axios.get('catalog/header', {params})
                    .then(function (response) {
                        self.categories = response.data.header.subcategories;
                        self.filtrosOpciones = response.data.dropdowns;
                        self.genres.push(...response.data.header.genres);
                        self.decades = response.data.header.decades;
                    })
                    .catch(function (error) {
                        // self.$utils.validate(error.response, self)
                    });
            },
            refresh: function (inputSearch) {
                this.loading = true;
                this.records = [];
                if (inputSearch !== undefined) {
                    this.querySearch = inputSearch
                }

                var self = this;
                this.$axios.get('catalog',
                    {
                        params: {
                            autor: this.activeTab ===4 ? this.activeArtist.value : this.filtros.autor,
                            derechos: this.filtros.derechos,
                            q: this.querySearch,
                            genre: this.activeSlug.value,
                            genre_id: this.filtros.genero,
                            p: this.pagination.page,
                            decade: this.activeDecade.value,
                            destacados: this.activeTab===1
                        }
                    })
                    .then(function (response) {
                        self.records = response.data.catalog;
                        self.pagination = response.data.pagination;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // self.$utils.validate(error.response, self);
                        self.loading = false;
                    })
            },
            genreRefresh: function () {
                this.pagination.page = 1;
                this.$router.replace(`/generos/${this.activeSlug.value}`);
                this.refresh()
            },
            decadeRefresh: function () {
                this.pagination.page = 1;
                this.refresh()
            }
        }
    }
</script>

<style scoped>
    @media(max-width: 950px){
        .action-records{
            position: fixed;
            bottom:0;
            z-index:10;
            background: #fff;
            left:0;
            right:0;

        }
    }

</style>
