// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex';
import App from './App'
import router from './router'
import axios from 'axios'
import './assets/css/font-awesome/css/all.css';
import './assets/css/normalize.css';
import './assets/css/slick.css';
import './assets/css/naui.css';
import './assets/css/brandbook.css';
import './assets/css/styles.css';
import vuetify from '@/plugins/vuetify';
import {utils} from './components/utils';
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = false;

Vue.prototype.$utils = utils;

Vue.use({
    install(Vue) {
        Vue.prototype.$axios = axios.create({
            baseURL: process.env.VUE_APP_BACKEND,
            timeout: 10000 * 6 * 5,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    }
});
Vue.use(VueAnalytics, {
    id: 'UA-159504669-1',
    router
})

Vue.use(Vuex);

Vue.prototype.$axios.interceptors.request.use(function (config) {
    const auth_token = localStorage.getItem('token');
    if (auth_token) {
        config.headers.Authorization = `Bearer ${auth_token}`;
    }

    return config;
}, function (err) {
    return Promise.reject(err);
});

const cart = new Vuex.Store({
    state: {
        songs: JSON.parse(localStorage.getItem('quoteSongs')) || [],
        prohibited: JSON.parse(localStorage.getItem('prohibited')) || '*'
    },
    mutations: {
        refreshProhibited(state, prohibited) {
            state.prohibited = prohibited
            localStorage.setItem('prohibited', JSON.stringify(prohibited))
        },
        add(state, song) {
            if (song instanceof Array) {
                state.songs.push(...song.filter((i) => {
                    const found = state.songs.find((s) => s.id === i.id);
                    return !found;
                }));
            } else {
                const found = state.songs.find((s) => s.id === song.id);
                if (!found) {
                    state.songs.push(song);
                }
            }
            localStorage.setItem('quoteSongs', JSON.stringify(state.songs));
        },
        remove(state, song) {
            if (song instanceof Array) {
                song.filter((i) => {
                    return state.songs.findIndex((s) => s.id === i.id);
                }).forEach((index) => {
                    state.songs.splice(index, 1);
                });
            } else {
                const index = state.songs.findIndex((s) => {
                    return s.id === song.id
                });
                if (index >= 0) {
                    state.songs.splice(index, 1);
                }
            }
            localStorage.setItem('quoteSongs', JSON.stringify(state.songs));
        }
    }
});

export default cart;


Vue.filter('uppercase', function (value) {
    return value.toUpperCase()
});

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
