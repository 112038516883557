<template>
    <main class="box-center">
        <v-text-field color="#24404F" v-if="loading" loading disabled></v-text-field>
        <div class="login-box rounded naui-shadow" v-if="!loading">
            <div class="box-center">
                <img height="70" src="@/assets/pix/landing/logo.png">
            </div>
            <div class="login-form">
                <v-text-field
                        label="Contraseña"
                        :type="show.password ? 'text':'password'"
                        v-model="password.password"
                        :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.password = !show.password"
                        outlined
                ></v-text-field>
                <v-text-field
                        name="input-10-2"
                        label="Confirmar contraseña"
                        v-model="password.confirm"
                        :type="show.confirm ? 'text':'password'"
                        :append-icon="show.confirm ? 'mdi-eye' : 'mdi-eye-off'"
                        class="input-group--focused"
                        @click:append="show.confirm = !show.confirm"
                        outlined
                ></v-text-field>
                <div class="btn btn-rojo text-center" @click="send()">
                    CAMBIAR CONTRASEÑA
                </div>
            </div>
        </div>
        <v-snackbar v-model="snack" :timeout="4000">
            {{message}}
            <v-btn color="blue" text @click="snack = false">Cerrar</v-btn>
        </v-snackbar>
    </main>
</template>

<script>
    export default {
        name: 'RecoveryForm',
        components: {},
        data: () => {
            return {
                snack: false,
                show: {
                    password: false,
                    confirm: false
                },
                password: {
                    password: '',
                    confirm: ''
                },
                message: '',
                loading: true,
                token: '',
                email: ''
            }
        },
        mounted() {
            this.token = this.$route.query.token || '';
            this.check();
        },
        methods: {
            send: function () {
                if (this.valid) {
                    const self = this;
                    this.loading = true;
                    this.$axios.post('/change-password', {
                        email: this.email,
                        password: this.password.password,
                        confirm_password: this.password.confirm,
                        token: this.token
                    }).then((response) => {
                        self.loading = false;
                        self.snack = true;
                        self.message = response.data.message;
                        self.password.password = '';
                        self.password.confirm = '';
                        setTimeout(() => {self.$router.push('/login');}, 5000);
                    }).catch((error) => {
                        self.loading = false;
                        self.message = 'Se presento un error al actualizar la contraseña';
                        self.snack = true;
                    });
                } else {
                    this.message = 'Las contraseñas no coinciden';
                    this.snack = true;
                }
            },
            check: function () {
                this.loading = true;
                const self = this;
                this.$axios.get(`/change-password?token=${this.token}`,).then((response) => {
                    self.email = response.data.email;
                    self.loading = false;
                }).catch((error) => {
                    self.loading = false;
                    self.$router.push('/');
                });
            }
        },
        computed: {
            valid: function () {
                return !!this.password.password && this.password.password === this.password.confirm;
            }
        }
    }
</script>
<style scoped>
    main {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, #F3F4F6 50%, #828282 150%);
    }

    .login-box {
        padding: 2rem;
        background-color: #fff;
        min-width: 400px;
    }

    .login-form {
        margin-top: 1.5rem;
    }

    .btn-rojo {
        font-weight: 600;
        padding: 1rem;
    }

</style>
