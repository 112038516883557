<template>
    <main>
        <simple-header :title="'Mis Listas'" :link="false"></simple-header>
        <section>
            <div class="container-fluid pad">
                <mis-listas></mis-listas>
            </div>
        </section>
    </main>

</template>

<script>
    import HeaderClient from "../../components/HeaderClient";
    import SimpleHeader from "../../components/SimpleHeader";
    import MisListas from "./partials/MisListas";

    export default {
        name: "ClientListas",
        components: {MisListas, SimpleHeader, HeaderClient}
    }
</script>

<style scoped>

</style>
