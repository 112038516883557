<template>
    <section class="nav-analytics">
        <nav class="box-left spaced">
            <a v-for="item in links" :class="{active: activeTab.value===item.value }" @click="activeTab.value=item.value" class="nav-item box-left spaced menu-item">
                <span>{{ item.label }}</span>
            </a>
        </nav>
    </section>
</template>

<script>
    export default {
        name: "NavAnalytics",
        props: ['activeTab'],
        data() {
            return {
                links: [
                    {
                        label: 'Tráfico General',
                        value: 1
                    },
                    {
                        label: 'Búsquedas generales',
                        value: 2
                    },
                    {
                        label: 'Géneros',
                        value: 3
                    },
                    {
                        label: 'Artistas',
                        value: 4
                    },
                    {
                        label: 'Canciones más buscadas',
                        value: 5
                    },
                    {
                        label: 'Canciones más reproducidas',
                        value: 6
                    },
                    {
                        label: 'Ranking por catálogo',
                        value: 7
                    },
                    {
                        label: 'Clientes',
                        value: 8
                    },
                ],
            }
        }
    }
</script>

<style scoped>
    .nav-analytics {
        margin: 0;
        background-color: #f3f4f6;
        overflow-x: scroll;
    }

    .nav-analytics::-webkit-scrollbar{
      display: none;
    }

    a {
        cursor: pointer;
    }

    nav {
        padding: .5rem 1rem;
        display: flex;
    }

    .nav-item {
        padding: .25rem .5rem;
        border-radius: 10px;
        color: #333;
        border: 1px solid transparent;
        min-width: max-content;
        font-size: .9rem;
    }

    .nav-item.active {
        border: 1px solid #BB1735;
    }

    .nav-item.active i {
        color: #BB1735;
    }

</style>
