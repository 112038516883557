<template>
    <div>
        <aside class="action-overlay" :class="{active: active.value}">
            <div class="header">
                <div class="box-center double-spaced">
                    <img height="17" src="@/assets/icons/agregar-blanco.svg">
                    <span>Crear nueva categoría</span>
                </div>
            </div>
            <div class="body">
                <div class="block">
                    <div class="block">
                        <v-form class="form-group" ref="form">
                            <v-text-field :error-messages="errorMessages.name" v-model="newObject.name"
                                          :rules="[rules.required]"
                                          label="Nombre de la categoría"></v-text-field>
                            <v-text-field :error-messages="errorMessages.slug" v-if="object!=='category'"
                                          v-model="newObject.slug"
                                          :rules="[rules.required, rules.isSlug]" label="Slug"></v-text-field>
                            <v-file-input v-if="object!=='category'" v-model="newObject.img"
                                          label="Cargar carátula" accept="image/*"></v-file-input>
                            <v-select v-if="object!=='category'" v-model="newObject.is_active"
                                      :items="[{text:'Activo', value:true}, {text:'No activo', value:false}]"
                                      label="Estado"></v-select>
                            <v-checkbox v-if="object!=='category'" v-model="newObject.highlight"
                                        label="Destacar en home"></v-checkbox>
                        </v-form>
                        <br>
                        <div @click="checkObject" class="form-group box-center">
                            <div class="btn-form btn btn-rojo">GUARDAR</div>
                        </div>
                        <div class="form-group box-center" @click="active.value=false">
                            <div class="btn-form btn btn-outline">CANCELAR</div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <div class="back-overlay" :class="{active: active.value}" @click="active.value=false"></div>
    </div>
</template>

<script>
    export default {
        name: "FormAsideNew",
        props: ['active', 'object', 'catId'],
        data: () => ({
            newObject: {
                name: '',
                slug:'',
                img: [],
                is_active: false,
                highlight: false
            },
            rules: {
                required: value => !!value || 'Requerido.',
                isSlug: value => /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(value) || 'Necesita ser una url valida.'
            },
            errorMessages: {
                name: [],
                slug: []
            }
        }),
        methods: {
            checkObject: function () {
                if (this.$refs.form.validate()) {
                    switch (this.object) {
                        case 'category':
                            this.newCategory();
                            break;
                        case 'subcategory':
                            this.newSubcategory();
                            break;
                        default:
                            break;
                    }
                }
            },
            newCategory: function () {
                var self = this;
                this.$axios.post('admin-app/categorias/crear', {name: this.newObject.name})
                    .then(function (response) {
                        self.$emit('saved', response);
                        self.active.value = false
                    })
                    .catch(function (error) {
                        if (error.response.status === 409) {
                            self.errorMessages.name = [error.response.data.msg];
                        }
                        self.$utils.validate(error.response, self);
                    })
            },
            newSubcategory: function () {
                var self = this;
                var formData = new FormData();
                var uploadObject = {...this.newObject};
                delete uploadObject['img'];
                formData.append("file", this.newObject.img);
                formData.append("data", JSON.stringify(uploadObject));
                this.$axios.post(`admin-app/categorias/${this.catId}/crear`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.$emit('saved', response);
                        self.active.value = false;
                    })
                    .catch(function (error) {
                        if (error.response.status === 409) {
                            if (error.response.data.error_name) self.errorMessages.name = [error.response.data.error_name];
                            if (error.response.data.error_slug) self.errorMessages.slug = [error.response.data.error_slug];
                        }
                        self.$utils.validate(error.response, self);
                    })
            }
        },
        watch: {
            'newObject.name': function (newVal, oldVal) {
                if (this.object === 'subcategory') {
                    var oldSlug = this.$utils.slug(oldVal);
                    if (this.newObject.slug === oldSlug || this.newObject.slug === '' || this.newObject.slug === undefined) {
                        this.newObject.slug = this.$utils.slug(newVal);
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .action-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 350px;
        background-color: #fff;
        z-index: 99;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        transform: translateX(100%);
        transition: 0.2s all ease-in-out;
    }

    .action-overlay.active {
        transform: translateX(0%);
    }

    .back-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        z-index: 98;
    }

    .back-overlay.active {
        bottom: 0;
        opacity: 1;
    }

    .header {
        background-color: #2B4959;
        padding: 1.5rem 1rem;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 600;
    }

    dd, dt {
        border-bottom: 1px solid #eaeaea;
        padding: 1rem 0;
    }

    dd {
        color: #5788A8;
        cursor: pointer;
        padding-left: .5rem;
        transition: .2s;
    }

    dd:hover {
        background-color: #f7f8f9;
    }

    .action-overlay {
        display: flex;
        flex-direction: column;
    }

    .action-overlay .body {
        flex: 100%;
    }

    .actions .block {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .btn-form {
        width: 200px;
        text-align: center;
    }
</style>
