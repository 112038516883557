<template>
  <section class="back-gris scrollable">
    <div class="container-fluid tabs-wrap">
      <div class="box-left tabs">
        <a class="tab" :class="{active: activeDecade.value==='' }" @click="changed('')">TODOS</a>
        <a class="tab" :class="{active: activeDecade.value===item }" v-for="item in tabs" @click="changed(item)">{{ item
          }}</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DecadeTabs",
  props: [ 'tabs', 'activeDecade' ],
  data() {
    return {}
  },
  methods: {
    changed: function (newVal) {
      if (this.activeDecade !== newVal) {
        this.activeDecade.value = newVal;
        this.$emit('refresh');
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 1000px) {
  .tabs {
    flex-wrap: wrap;
  }

  .tabs .tab {
    margin-bottom: .5rem;
  }
}

.tab {
  cursor: pointer;
  width: max-content;
  min-width: 120px;
  padding: .5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 2rem;
  font-size: .8rem;
  margin-right: .75rem;
  font-weight: 600;
}

.tabs-wrap{
  padding-top:1.25rem;
  padding-bottom:.75rem;
}

.tabs > .tab {
  border-color: #B81432;
  color: #B81432;
}

.tabs > .tab.active {
  background-color: #B81432;
  border-color: #B81432;
  color: #fff;
}

.tabs > .tab:nth-child(1) {
  border-color: #487E9C;
  color: #487E9C;
}

.tabs > .tab.active:nth-child(1) {
  background-color: #487E9C;
  border-color: #487E9C;
  color: #fff;
}

.tabs > .tab:nth-child(2) {
  border-color: #5E6987;
  color: #5E6987;
}

.tabs > .tab.active:nth-child(2) {
  background-color: #5E6987;
  border-color: #5E6987;
  color: #FFF;
}

.tabs > .tab:nth-child(3) {
  border-color: #755472;
  color: #755472;
}

.tabs > .tab.active:nth-child(3) {
  background-color: #755472;
  border-color: #755472;
  color: #FFF;
}

.tabs > .tab:nth-child(4) {
  border-color: #8B3E5C;
  color: #8B3E5C;
}

.tabs > .tab.active:nth-child(4) {
  background-color: #8B3E5C;
  border-color: #8B3E5C;
  color: #FFF;
}

.tabs > .tab:nth-child(5) {
  border-color: #A22947;
  color: #A22947;
}

.tabs > .tab.active:nth-child(5) {
  background-color: #8B3E5C;
  border-color: #8B3E5C;
  color: #FFF;
}

.tabs > .tab:nth-child(6) {
  border-color: #B81432;
  color: #B81432;
}

.tabs > .tab.active:nth-child(6) {
  background-color: #B81432;
  border-color: #B81432;
  color: #FFF;
}


@media (max-width: 1000px) {
  .scrollable {
    padding-right: 0;
    overflow-x: scroll;
  }

  .scrollable::-webkit-scrollbar {
    display: none;
  }

  .tabs-wrap {
    padding: 1rem 0;
  }

  .tabs {
    width: max-content;
  }

  .tab {
    width: max-content;
  }

}

</style>
