<template>
    <div v-if="song">
        <simple-header :link="false" :title="song.title"></simple-header>
        <div class="container-fluid">
            <div class="gridSong">
                <div class="left">
                    <div class="player-wrap">
                        <Player v-if="song" :song="song"></Player>
                        <div class="block form-player">
                            <div class="form-group">
                                <v-file-input
                                        v-model="newCover"
                                        label="Cargar carátula"
                                        accept="image/*"
                                        prepend-icon="mdi-camera"
                                ></v-file-input>
                            </div>
                            <div class="form-group">
                                <div class="btn btn-outline box-left spaced"
                                     @click="addToCat">
                                    <img src="@/assets/icons/estrella.svg">
                                    <span>AGREGAR A CATEGORÍA</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="btn btn-rojo box-center spaced"
                                     @click="saveSong">
                                    <span>GUARDAR</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="gContent">
                        <div class="content-left">
                            <FormSong :cancion="song" :readonly="readonly"></FormSong>
                        </div>
                        <div class="content-right">
                            <div class="form-group">
                                <!--<label>Letra</label>-->
                                <v-textarea class="letra-textarea"
                                        label="Letra"
                                        v-model="song.lyrics"
                                        :readonly="readonly"
                                ></v-textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <agregar-a-catalogo :active="actionOverlay" :records="sendSongs"></agregar-a-catalogo>
        <v-snackbar v-model="savedSnackbar" :timeout="4000">
            Datos guardados
            <v-btn color="blue" text @click="savedSnackbar = false">Cerrar</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import Player from "@/components/widgets/Player";
    import FormSong from "../../components/forms/FormSong";
    import RelatesSongs from "@/components/tables/RelatesSongs";
    import AgregarLista from "@/components/AgregarLista";
    import AdminHeader from "./partials/AdminHeader";
    import AgregarACatalogo from "../../components/AgregarACatalogo";
    import SimpleHeader from "../../components/SimpleHeader";

    export default {
        name: "AdminSong",
        components: {SimpleHeader, AgregarACatalogo, AdminHeader, RelatesSongs, FormSong, Player, AgregarLista},
        data() {
            return {
                song: undefined,
                actionOverlay: {value: false},
                sendSongs: [],
                newCover: undefined,
                savedSnackbar: false,
                readonly: false
            }
        },
        mounted() {
            this.readonly = !this.$route.query.hasOwnProperty('editar');
            this.getSong(this.$route.params.id);
        },
        methods: {
            saveSong: function () {
                var self = this;
                var formData = new FormData();
                var uploadObject = {...this.song};
                delete uploadObject['cover'];
                formData.append("file", this.newCover);
                formData.append("data", JSON.stringify(uploadObject));
                this.$axios.post(`admin-app/cancion/${this.song.id}/editar`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.savedSnackbar = true;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self);
                    })
            },
            addToCat: function () {
                this.sendSongs = [this.song.id];
                this.openModalList();
            },
            openModalList: function () {
                if (this.actionOverlay.value === false) this.actionOverlay.value = true;
            },
            getSong: function (id) {
                var self = this;
                this.$axios.get(`admin-app/cancion/${id}`)
                    .then(function (response) {
                        self.song = response.data.song;
                        window.scrollTo(0, 0);
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    });
            }
        }
    }
</script>

<style scoped>

    .player-wrap {
        position: sticky;
        top: calc(74px + 2rem);
    }

    .gridSong {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 2rem 1rem;
        grid-gap: 4rem;
    }

    .gContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4rem;
    }

    .letra {
        padding: 1rem;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        background-color: #FBFBFB;
    }

    .form-player {
        margin-top: 1.5rem;
    }

    .letra-textarea textarea{
        min-height: 400px !important;
    }

    .v-textarea textarea{
        min-height: 400px;
    }

    @media(max-width: 1024px){
      .gridSong{
        grid-template-columns: 1fr;
        grid-gap:1rem;
        padding:0;
        padding-top:1rem;
      }
      .gContent{
        grid-template-columns: 1fr;
        grid-gap:1rem;
      }

    }

</style>
