<template>
    <section class="pad20">
        <div class="container-fluid regular">
            <div class="grid3 gap30 rgrid w1100 m-auto">
                <template v-for="item in features">
                    <div class="feature text-center">
                        <div class="box-center">
                            <img :src="item.image">
                        </div>
                        <p class="subtitle">{{ item.title }}</p>
                        <p>{{ item.text }}</p>
                    </div>
                </template>
            </div>
            <div class="box-center pad">
                <div @click="$emit('contact')" class="btn btn-rojo">CONTÁCTENOS</div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SplashFeatures",
        props: ['features']
    }
</script>

<style scoped>

    .feature img{
        margin-bottom: 1rem;
    }

    .feature .subtitle{
        margin-bottom:1rem;
        display: block;
    }

    .feature>p:nth-child(3){
        max-width: 270px;
        margin: 0 auto;
    }

    .btn-rojo{
        border-radius: 3rem;
        width: 200px;
        text-align: center;
        background: linear-gradient(to right, #F11B46, #BD1231);
        font-weight: 600;
        height: 50px;
        font-size: .9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .btn-rojo:active{
        background:#BD1231;
    }

    @media(max-width: 950px){
        .feature img{
            margin-bottom:.5rem;
        }

        .grid3{
            grid-gap: 1.5rem;
            margin-bottom:1rem;
        }
        section.pad20{
            padding:1.5rem 0;
        }
    }


</style>
