<template>
    <section class="back-gris">
        <div class="container-fluid regular">
            <div class="title">
                <p class="text-center subtitle">{{ intro }}</p>
            </div>
            <div>
                <slick ref="slick" :options="slickOptions">
                    <div v-for="item in productions">
                            <img class="w-100 rounded" :src="item.image">
                    </div>
                </slick>
            </div>
        </div>

    </section>

</template>

<script>
    import Slick from 'vue-slick';

    export default {
        name: "SplashCatalog",
        components: {Slick},
        props: {
            intro: {
                type: String,
                default: ''
            },
            productions: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                slickOptions: {
                    slidesToShow: 3,
                    arrows: false,
                    dots:true,
                    autoplay: false
                }
            }
        }
    }
</script>

<style scoped>
    .title p {
        margin: 0;
    }

    .title {
        margin-bottom: 1.5rem;
    }

    section {
        padding: 2rem 0;
    }

</style>
