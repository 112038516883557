<template>
  <section>
      <div :class="'gTableHeader '+gridColumns">
        <div class="text-center">Posición</div>
        <div class="text-center" v-for="header in headers">{{ header.text }}</div>
      </div>
      <template v-for="(item, index) in records">
        <div :class="'gRecordItem '+gridColumns">
          <div class="text-center"><span>{{ index + 1 }}</span></div>
          <div v-for="header in headers" class="text-center">
            <span>{{ item[header.value] }}</span>
          </div>
        </div>
      </template>
  </section>
</template>

<script>
export default {
  name: "AnalyticsTable",
  props: [ 'headers', 'records' ],
  computed: {
    gridColumns: function () {
      var num = this.headers.length;
      return 'cols' + (num + 1)
    }
  }
}
</script>

<style scoped>
.gTableHeader {
  width: 100%;
}

.gTableHeader > div {
  height: auto;
  line-height: 1;
  padding-top: .25rem;
  padding-bottom:.25rem;
}

.cols2 {
  grid-template-columns: 40% 60%;
  max-width: 600px;
}

.cols3 {
  grid-template-columns: 20% 40% 40%;
  max-width: 700px;
}

.cols4 {
  grid-template-columns: 25% 25% 25% 25%;
  max-width: 800px;
}

.gRecordItem {
  display: grid;
}

thead tr {
  background: linear-gradient(to right, #24404F 0%, #487E9C 100%);
}
</style>
