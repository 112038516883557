<template>
    <div class="player" v-if="song">
        <div class="naui-overlay-container">
            <img class="w-100" :src="song.cover">
            <div class="naui-overlay box-center">
                <img height="50"
                     src="@/assets/icons/pausa.svg"
                     v-if="playing"
                     v-on:click="pause()"
                     style="cursor: pointer">
                <img height="50"
                     src="@/assets/icons/play-lista.svg"
                     v-else
                     v-on:click="play()"
                     style="cursor: pointer">
            </div>

        </div>
        <div class="wrapBar">
            <div class="playerBar">
                <div class="slidecontainer">
                    <input type="range"
                           :min="0"
                           :max="duration"
                           v-model="current"
                           @change="update" @mousedown="changing=true" @mouseup="changing=false"
                           class="slider w-100" id="myRange">
                </div>
            </div>
            <div class="box-center extended">
                <div class="time">{{time}}</div>
                <div class="time">{{total}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Player",
        props: ['song'],
        data() {
            return {
                player: null,
                playing: false,
                changing: false,
                duration: 0,
                current: 0
            }
        },
        mounted() {
            this.player = new Audio(this.song.song);
            this.player.ontimeupdate = (event) => {
                if (this.playing) {
                    if (!this.changing) {
                        this.current = this.player.currentTime;
                    }
                    this.duration = this.player.duration;
                }
            };
            this.player.onended = () => {
                this.pause();
                this.current = 0;
            }
        },
        methods: {
            sendPlayedSong: function () {
                this.$axios.post('played-song', {id: this.song.id})
                    .then(function (response) {
                    })
                    .catch(function (error) {
                        // self.$utils.validate(error.response, self)
                    });
            },
            play: function () {
                this.player.play();
                this.playing = true;
                this.sendPlayedSong();
            },
            pause: function () {
                this.player.pause();
                this.playing = false;
            },
            update: function (event) {
                var resume = this.playing
                try {
                    this.changing = false;
                    this.pause();
                    this.player.currentTime = parseFloat(event.target.value);
                } catch (e) {
                }
                if (resume) this.play();
            }
        },
        beforeDestroy() {
            this.pause();
        },
        computed: {
            total: function () {
                let seconds = Math.floor(this.duration % 60);
                return Math.floor(this.duration / 60) + ':' + (seconds < 10 ? '0' + seconds : seconds);
            },
            time: function () {
                let seconds = Math.floor(this.current % 60);
                return Math.floor(this.current / 60) + ':' + (seconds < 10 ? '0' + seconds : seconds);
            },
            icon: function () {
                return this.playing ? '/assets/icons/pausa.svg' : '/assets/icons/play-lista.svg';
            },
        }
    }
</script>

<style scoped>
    .player {
        max-width: 250px;
    }

    .playerBar {
        padding: 1rem;
        padding-bottom: .15rem;
    }

    .time {
        font-size: .8rem;
    }

    .slidecontainer {
        width: 100%; /* Width of the outside container */
    }

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 10px;
        border-radius: 7px;
        background: #BB1735;
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fcfcfc;
        cursor: pointer;
        border: 1px solid #707070;
    }

    .slider::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #fcfcfc;
        cursor: pointer;
        border: 1px solid #707070;
    }

</style>
