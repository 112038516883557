<template>
    <line-chart ref="chart" :styles="myStyles"></line-chart>
</template>

<script>
    import LineChart from "../../../../components/charts/LineChart";

    export default {
        name: "AnalyticsChart",
        components: {LineChart},
        props: ['filterOn', 'chartData'],
        data: () => ({
            myStyles: {
                height: '300px',
                position: 'relative'
            }
        }),
        mounted() {
            this.$refs.chart.render(this.chartData['monthly'])
        },
        methods: {
            renderData: function () {
                this.$refs.chart.render(this.chartData['monthly'])
            }
        },
        watch: {
            'filterOn': function (newVal) {
                if(newVal === 0 || newVal === undefined) {
                    this.$refs.chart.render(this.chartData['monthly'])
                } else {
                    this.$refs.chart.render(this.chartData['daily'][newVal])
                }
            }
        }
    }
</script>

<style scoped>

</style>
