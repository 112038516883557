<template>
    <v-dialog v-model="dialog" width="300">
        <v-card>
            <v-card-title class="headline" v-if="title">{{ title }}</v-card-title>
            <v-card-text v-if="text">{{ text }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('ok')">
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            dialog: {
                type: Boolean
            },
            text: {
                type: String,
                default: undefined
            },
            title: {
                type: String,
                default: undefined
            }},
        name: "InformationDialog"
    }
</script>

<style scoped>

</style>
