<template>
    <section>
        <div class="container-fluid">
            <div class="w700">
                <div class="gTableHeader">
                    <div>Nombre</div>
                    <div>Canciones</div>
                    <div>Acciones</div>
                </div>
                <div class="gRecordItem" v-for="item in subcategories">
                    <div class="text-left">
                        <span>{{ item.name }}</span>
                    </div>
                    <div class="text-center">
                        <span>{{ item.count }}</span>
                    </div>
                    <div>
                        <div class="box-center actions double-spaced">
                            <router-link :to="`/admin/categorias/${$route.params.id}/${item.slug}`">
                                <img height="25" src="@/assets/icons/autoral.svg">
                            </router-link>
                            <div>
                                <a @click="selectedListId = item.id; dialog.value = true"><img height="25"
                                                                                               src="@/assets/icons/borrar.svg"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <yes-no-dialog :dialog="dialog"
                       title="¿Eliminar subcategoría?" text="¿Esta seguro de que quiere eliminar esta subcategoría?"
                       v-on:no="dialog.value = false"
                       v-on:yes="deleteSubcat"
        ></yes-no-dialog>
    </section>

</template>

<script>
    import YesNoDialog from "../../../components/dialogs/YesNoDialog";
    export default {
        name: "SubCategorias",
        components: {YesNoDialog},
        props: {
            subcategories: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            selectedListId: -1,
            dialog: {value:false},
            newCategory: '',
            creating: false,
            rules: {
                required: value => !!value || 'Requerido.'
            }
        }),
        mounted() {
        },
        methods: {
            deleteSubcat: function () {
                var id = this.selectedListId;
                var self = this;
                this.$axios.post(`admin-app/subcategorias/eliminar`, {id:id})
                    .then(function (response) {
                        self.subcategories = self.subcategories.filter(function (obj) {
                            return obj.id !== id;
                        });
                        self.dialog.value = false;
                    })
                    .catch(function (error) {
                        self.$utils.validate(error.response, self)
                    })
            }
        }
    }
</script>

<style scoped>
    .text-left {
        padding-left: 1.5rem;
    }

    .gRecordItem, .gTableHeader{
        grid-template-columns: 40% 30% 30%;
    }

</style>
